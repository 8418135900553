export const TABLE_HEAD = [{
        name: 'Client',
        selector: 'client_name',
        sortable: true,
        width: '23%'
    },
    {
        name: 'Division',
        selector: 'division_name',
        sortable: true,
        width: '10%'
    },
    {
        name: 'Country',
        selector: 'country_name',
        sortable: true,
        width: '10%'
    },
    {
        name: 'Start Date',
        selector: 'start_date',
        sortable: true,
        width: '12%'
    },
    {
        name: 'End Date',
        selector: 'end_date',
        sortable: true,
        width: '10%'
    },
    {
        name: 'Hours',
        selector: 'total_hours',
        sortable: true,
        width: '10%'
    },
    {
        name: 'Action',
        center: true,
        selector: 'action',
        sortable: false,
        width: '25%'
    }
];

export const EMPLOYEE_TABLE_HEAD = [{
    name: 'Employee',
    selector: 'employee_name',
    sortable: true,
    width: '25%'
}, {
    name: 'Practice',
    selector: 'practice_name',
    sortable: true,
    width: '15%'
}, {
    name: 'Manager',
    selector: 'manager_name',
    sortable: true,
    width: '25%'
}, {
    name: 'Cost',
    selector: 'employee_cost',
    sortable: true,
    width: '10%'
}, {
    name: 'Action',
    selector: 'action',
    sortable: false,
    center: true,
    width: '20%'
}]

export const COUNTRY_TABLE_HEAD = [{
    name: 'Country',
    selector: 'country_name',
    sortable: true,
    width: '70%'
}, {
    name: 'Action',
    selector: 'action',
    sortable: false,
    center: true,
    width: '30%'
}];
export const DIVISION_TABLE_HEAD = [{
        name: 'Country',
        selector: 'country_name',
        sortable: true,
        width: '35%'
    },
    {
        name: 'Division',
        selector: 'division_name',
        sortable: true,
        width: '35%'
    }, {
        name: 'Action',
        selector: 'action',
        sortable: false,
        center: true,
        width: '30%'
    }
];
export const CLIENT_TABLE_HEAD = [{
        name: 'Country',
        selector: 'country_name',
        sortable: true,
        width: '20%'
    },
    {
        name: 'Division',
        selector: 'division_name',
        sortable: true,
        width: '20%'
    },
    {
        name: 'Client',
        selector: 'client_name',
        sortable: true,
        width: '35%'
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: false,
        center: true,
        width: '25%'
    }
];

export const DAILY_TABLE_HEAD = [{
        name: 'Client',
        selector: 'client_name',
        sortable: true,
        width: '25%'
    },
    {
        name: 'Division',
        selector: 'division_name',
        sortable: true,
        width: '10%'
    },
    {
        name: 'Country',
        selector: 'country_name',
        sortable: true,
        width: '10%'
    },
    {
        name: 'Date',
        selector: 'work_date',
        sortable: true,
        width: '15%'
    },
    {
        name: 'Hours',
        selector: 'work_time',
        sortable: true,
        width: '15%'
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: false,
        center: true,
        width: '25%'
    }
];

export const VERIFY_TABLE_HEAD = [{
        name: '',
        selector: 'log_checkbox',
        sortable: false,
        center: true,
        width: "2%",
    },
    {
        name: 'Employee Name',
        selector: 'employee_name',
        sortable: false,
        right: true,
        width: "18%",
    },
    {
        name: 'Division Name',
        selector: 'division_name',
        sortable: false,
        right: true,
        width: "12%",
    },
    {
        name: 'Client Name',
        selector: 'client_name',
        sortable: false,
        right: true,
        width: "20%",
    },
    {
        name: 'Date',
        selector: 'work_date',
        sortable: true,
        right: true,
        width: "10%",
    },
    {
        name: 'Hours',
        selector: 'work_time',
        sortable: false,
        right: true,
        width: "10%",
    },
    // {
    //     name: 'Comments',
    //     selector: 'comments',
    //     sortable: false,
    //     right: true,
    //     width: "20%",
    // },
    {
        name: 'Action',
        selector: 'action',
        sortable: false,
        right: true,
        width: "25%",
    }
];

export const ORIGIN = window.location.origin

export const TIME_OPTIONS = [
    { value: '00:00', label: '00:00', selected: true },
    { value: '00:15', label: '00:15', selected: false },
    { value: '00:30', label: '00:30', selected: false },
    { value: '00:45', label: '00:45', selected: false },
    { value: '01:00', label: '01:00', selected: false },
    { value: '01:15', label: '01:15', selected: false },
    { value: '01:30', label: '01:30', selected: false },
    { value: '01:45', label: '01:45', selected: false },
    { value: '02:00', label: '02:00', selected: false },
    { value: '02:15', label: '02:15', selected: false },
    { value: '02:30', label: '02:30', selected: false },
    { value: '02:45', label: '02:45', selected: false },
    { value: '03:00', label: '03:00', selected: false },
    { value: '03:15', label: '03:15', selected: false },
    { value: '03:30', label: '03:30', selected: false },
    { value: '03:45', label: '03:45', selected: false },
    { value: '04:00', label: '04:00', selected: false },
    { value: '04:15', label: '04:15', selected: false },
    { value: '04:30', label: '04:30', selected: false },
    { value: '04:45', label: '04:45', selected: false },
    { value: '05:00', label: '05:00', selected: false },
    { value: '05:15', label: '05:15', selected: false },
    { value: '05:30', label: '05:30', selected: false },
    { value: '05:45', label: '05:45', selected: false },
    { value: '06:00', label: '06:00', selected: false },
    { value: '06:15', label: '06:15', selected: false },
    { value: '06:30', label: '06:30', selected: false },
    { value: '06:45', label: '06:45', selected: false },
    { value: '07:00', label: '07:00', selected: false },
    { value: '07:15', label: '07:15', selected: false },
    { value: '07:30', label: '07:30', selected: false },
    { value: '07:45', label: '07:45', selected: false },
    { value: '08:00', label: '08:00', selected: false },
    { value: '08:15', label: '08:15', selected: false },
    { value: '08:30', label: '08:30', selected: false },
    { value: '08:45', label: '08:45', selected: false },
    { value: '09:00', label: '09:00', selected: false },
    { value: '09:15', label: '09:15', selected: false },
    { value: '09:30', label: '09:30', selected: false },
    { value: '09:45', label: '09:45', selected: false },
    { value: '10:00', label: '10:00', selected: false },
    { value: '10:15', label: '10:15', selected: false },
    { value: '10:30', label: '10:30', selected: false },
    { value: '10:45', label: '10:45', selected: false },
    { value: '11:00', label: '11:00', selected: false },
    { value: '11:15', label: '11:15', selected: false },
    { value: '11:30', label: '11:30', selected: false },
    { value: '11:45', label: '11:45', selected: false },
    { value: '12:00', label: '12:00', selected: false },
    { value: '12:15', label: '12:15', selected: false },
    { value: '12:30', label: '12:30', selected: false },
    { value: '12:45', label: '12:45', selected: false },
    { value: '13:00', label: '13:00', selected: false },
    { value: '13:15', label: '13:15', selected: false },
    { value: '13:30', label: '13:30', selected: false },
    { value: '13:45', label: '13:45', selected: false },
    { value: '14:00', label: '14:00', selected: false },
    { value: '14:15', label: '14:15', selected: false },
    { value: '14:30', label: '14:30', selected: false },
    { value: '14:45', label: '14:45', selected: false },
    { value: '15:00', label: '15:00', selected: false },
    { value: '15:15', label: '15:15', selected: false },
    { value: '15:30', label: '15:30', selected: false },
    { value: '15:45', label: '15:45', selected: false },
    { value: '16:00', label: '16:00', selected: false },
    { value: '16:15', label: '16:15', selected: false },
    { value: '16:30', label: '16:30', selected: false },
    { value: '16:45', label: '16:45', selected: false },
    { value: '17:00', label: '17:00', selected: false },
    { value: '17:15', label: '17:15', selected: false },
    { value: '17:30', label: '17:30', selected: false },
    { value: '17:45', label: '17:45', selected: false },
    { value: '18:00', label: '18:00', selected: false },
    { value: '18:15', label: '18:15', selected: false },
    { value: '18:30', label: '18:30', selected: false },
    { value: '18:45', label: '18:45', selected: false },
    { value: '19:00', label: '19:00', selected: false },
    { value: '19:15', label: '19:15', selected: false },
    { value: '19:30', label: '19:30', selected: false },
    { value: '19:45', label: '19:45', selected: false },
    { value: '20:00', label: '20:00', selected: false },
    { value: '20:15', label: '20:15', selected: false },
    { value: '20:30', label: '20:30', selected: false },
    { value: '20:45', label: '20:45', selected: false },
    { value: '21:00', label: '21:00', selected: false },
    { value: '21:15', label: '21:15', selected: false },
    { value: '21:30', label: '21:30', selected: false },
    { value: '21:45', label: '21:45', selected: false },
    { value: '22:00', label: '22:00', selected: false },
    { value: '22:15', label: '22:15', selected: false },
    { value: '22:30', label: '22:30', selected: false },
    { value: '22:45', label: '22:45', selected: false },
    { value: '23:00', label: '23:00', selected: false },
    { value: '23:15', label: '23:15', selected: false },
    { value: '23:30', label: '23:30', selected: false },
    { value: '23:45', label: '23:45', selected: false }
];