import React, { Component } from "react";
import Select from 'react-select';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import Autosuggest from 'react-autosuggest';


import {
  BASE_URL,
  fetchPracticeList,
  fetchEmployeeList,
  fetchAllCountry,
  fetchAllChargedBy
} from "../helper/helper.jsx"

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Dialog from 'react-bootstrap-dialog'

import Cookies from 'js-cookie';
import { EMPLOYEE_TABLE_HEAD } from '../helper/data'
import { query } from "chartist";
const axios = require('axios');

let employee_columns = EMPLOYEE_TABLE_HEAD
let userData = {}
if (Cookies.get('userData')) {
  userData = JSON.parse(Cookies.get('userData'))
}

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practices: [],
      suggested_employees: [],
      filter_employees: [],
      all_employees: [],
      employee_email: '',
      employee_other_email: '',
      PracticeSelectedOption: null,
      userName: '',
      employees: [],
      manager_employees: [],
      employee_already_exist: false,
      ManagerSelectedOption: null,
      cost: 120,
      disableEmail: false,
      employeeID: null,
      Filter_EmployeeSelectedOption: null,
      Filter_ManagerSelectedOption: null,
      CountrySelectedOption: null,
      countries: [],
      ChargedBySelectedOption: null,
      chargedBy: []
    };
  }

  componentDidMount() {
    this.fetchPracticeList()
    this.fetchEmployeeList()
    this.fetchAllCountryList()
    this.fetchChargedByList()
  }

  clearFilters = () => {
    this.setState({ 
      Filter_EmployeeSelectedOption: null, 
      Filter_ManagerSelectedOption: null, 
    });
    this.fetchEmployeeList()
  }

  getLatestData(queryData = {}) {
    // console.log(queryData)
    // fetchFilterEmployee(queryData).then((data) => {
    //   let result_data = data.results
    //   result_data.map((value, index) => {
    //     result_data[index].action = this.renderButtonHTML(value)
    //   })
    //   this.setState({
    //     workLogs: result_data
    //   })
    // })
  }

  prepareQueryObj() {
    let temp = {}
    if (this.state.Filter_EmployeeSelectedOption !== null) {
      temp.employee_id = this.state.Filter_EmployeeSelectedOption.value
    }

    this.setState({
      queryData: temp
    }, () => {
      this.getLatestData(this.state.queryData)
    });

  }

  fetchPracticeList () {
    fetchPracticeList(userData).then((data) => {
      this.setState({
        practices: data[0].data.results,
      })
    }).catch(err => console.log(err))
  }

  renderButtonHTML = (data) => {
    return (
      <div className="table-btn-holder">
        <Button className="btn-edit" fill data-toggle="tooltip" title="Edit" onClick={() => this.editEmployee(data)}>
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </Button>
        <Button className="btn-delete ml-2" fill data-toggle="tooltip" title="Delete" onClick={() => this.deleteEmployee(data)}>
          <i className="fa fa-trash" aria-hidden="true"></i>
        </Button>
      </div>
    )
  }

  fetchEmployeeList () {
    fetchEmployeeList(userData).then((data) => {
      let all_employee_list = data[0].data.results.employees
      let all_employees = []
      all_employee_list.map((value, index) => {
        all_employee_list[index].action = this.renderButtonHTML(value)
        all_employees.push({
          value: value.id,
          label: value.employee_name
        })
      })

      this.setState({
        all_employees: all_employees,
        employees: all_employee_list,
        manager_employees: data[0].data.results.manager_employees,
        filter_employees: data[0].data.results.suggested_employee,
      })
    }).catch(err => console.log(err))
  }

  

  handlePracticeChange = (e) => {
    let temp = e.value
    this.setState({
      PracticeSelectedOption: e,
    });
  };

  handleUserNameChange = (e) => {
    this.setState({
      userName : e.target.value
    })
  }

  handleManagerChange = (e) => {
    let temp = e.value
    this.setState({
      ManagerSelectedOption : e
    })
  }

  getSuggestions = value => {
    let filter_employees = this.state.filter_employees
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
   
    let foundEmployee = inputLength === 0 ? [] : filter_employees.filter(employee =>
      employee.label.toLowerCase().slice(0, inputLength) === inputValue
    );
    this.setState({
      employee_already_exist: foundEmployee.length > 0 ? true : false
    })

    return foundEmployee
  };

  resetFields = () => {
    this.setState({
      employee_email: '',
      employee_other_email: '',
      PracticeSelectedOption: null,
      userName: '',
      employee_already_exist: false,
      ManagerSelectedOption: null,
      cost: 120,
      disableEmail: false,
      employeeID: null,
      CountrySelectedOption: null,
      ChargedBySelectedOption: null
    });
  }

  getSuggestionValue = suggestion => suggestion.label;

  renderSuggestion = suggestion => suggestion.label;

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggested_employees: this.getSuggestions(value)
    });
  };
 
  onSuggestionsClearRequested = () => {
    this.setState({
      suggested_employees: []
    });
  };
  suggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.setState({employee_email: suggestionValue})
  }

  handleEmailChange = (e) => {
    this.setState({employee_email: e.target.value})
  }

  handleEmailAddressChange = (e) => {
    this.setState({employee_other_email: e.target.value})
  }

  handleCostChange = (e) => {
    this.setState({cost: e.target.value})
  }

  validateEmail(email) { 
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(email)){
        if(email.indexOf("@publicisgroupe.net", email.length - "@publicisgroupe.net".length) !== -1){
            return true
        }
        else {
          return false
        }
    }
  }
  validateOtherEmail(email) { 
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(email)){
        if(email.indexOf("@publicismedia.com", email.length - "@publicismedia.com".length) !== -1){
            return true
        }
        else if(email.indexOf("@publicisgroupe.net", email.length - "@publicisgroupe.net".length) !== -1){
            return true
        }
        else if(email.indexOf("@publicisgroupe.com", email.length - "@publicisgroupe.com".length) !== -1){
          return true
        }
        else if(email.indexOf("@starcomww.com", email.length - "@starcomww.com".length) !== -1){
          return true
        }
        else if(email.indexOf("@sparkfoundryww.com", email.length - "@sparkfoundryww.com".length) !== -1){
          return true
        }
        else if(email.indexOf("@zenithmedia.com", email.length - "@zenithmedia.com".length) !== -1){
          return true
        }
        else if(email.indexOf("@performics.com", email.length - "@performics.com".length) !== -1){
          return true
        }
        else if(email.indexOf("@digitas.com", email.length - "@digitas.com".length) !== -1){
          return true
        }
        else if(email.indexOf("@leoburnett.com", email.length - "@leoburnett.com".length) !== -1){
          return true
        }
        else {
          return false
        }
    }
  }

  submitRequest = () => {
    if (this.state.PracticeSelectedOption == null) {
      toast.error("Please select practice.");
    }
    else if (this.state.userName == "") {
      toast.error("Please enter employee name.");
    }
    else if (this.state.cost == 0) {
      toast.error("Please provide cost.");
    }
    else if (this.state.employee_email == "" || !this.validateEmail(this.state.employee_email)) {
      toast.error("Please enter valid LionLogin ID.");
    }
    else if (this.state.employee_other_email == "" || !this.validateOtherEmail(this.state.employee_other_email)) {
      toast.error("Please enter valid email address.");
    }
    else if (this.state.ManagerSelectedOption == null) {
      toast.error("Please select manager.");
    }
    else if (this.state.CountrySelectedOption == null) {
      toast.error("Please select employee market.");
    }
    else if (this.state.ChargedBySelectedOption == null) {
      toast.error("Please select charged by type.");
    }
    else {
      this.setState({
        disableSaveButton: true
      }, () => {
        let url = BASE_URL + "/insertEmployee"
        let employee_data = {
          employee_id: this.state.employeeID,
          practice_id: this.state.PracticeSelectedOption.value,
          employee_name: this.state.userName,
          employee_cost: this.state.cost,
          employee_email: this.state.employee_email,
          employee_other_email: this.state.employee_other_email,
          employee_manager_id: this.state.ManagerSelectedOption.value,
          employee_market_id: this.state.CountrySelectedOption.value,
          employee_chargedBy_type_id: this.state.ChargedBySelectedOption.value
        }
        axios.post(url, {
          data: employee_data,
          token: userData.authToken
        })
          .then((response) => {
            this.resetFields();
            toast.success("Employee Created.");
            this.fetchEmployeeList()
          })
      });
    }

  }

  editEmployee = (data) => {
      let practiceFound = this.state.practices.find((elem) => {
        return elem.value === data.practice_id
      })
      let managerFound = this.state.manager_employees.find((elem) => {
        return elem.value === data.manager_id
      })
      let countryFound = this.state.countries.find((elem) => {
        return elem.value === data.country_id
      })
      let chargedByFound = this.state.chargedBy.find((elem) => {
        return elem.value === data.charged_id
      })
      this.setState({
        employeeID: data.id,
        PracticeSelectedOption: practiceFound,
        userName: data.employee_name,
        cost: data.employee_cost,
        employee_email: data.employee_email,
        employee_other_email: data.employee_mail,
        ManagerSelectedOption: managerFound,
        CountrySelectedOption: countryFound,
        ChargedBySelectedOption: chargedByFound,
        disableEmail: true,
      })

  }

  deleteEmployee = (data) => {
    this.dialog.show({
      title: 'Confirmation',
      body: 'Are you sure you want to delete this employee?',
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(() => {
          let url = BASE_URL + "/deleteEmployee"
          data.employee_id = userData["employee_id"]
          axios.post(url, {
            data: data,
            token: userData.authToken
          })
            .then((response) => {
              this.fetchEmployeeList()
              this.resetFields();
            })
            .catch(function (error) {
              console.log(error);
            });
        })
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  handleEmployeeChangeFilter = (e) => {
    console.log(e)
    let temp = e.value
    this.setState({
      Filter_EmployeeSelectedOption: e
    })
  };

  fetchAllCountryList () {
    fetchAllCountry().then((data) => {
      this.setState({
        countries: data[0].data.result
      })
    }).catch(err => console.log(err))
  }

  handleCountryChange = (e) => {
    let temp = e.value
    this.setState({
      CountrySelectedOption: e,
    });
  };

  fetchChargedByList () {
    fetchAllChargedBy().then((data) => {
      this.setState({
        chargedBy: data[0].data.result
      })
    }).catch(err => console.log(err))
  }

  handleChargedByChange = (e) => {
    let temp = e.value
    this.setState({
      ChargedBySelectedOption: e,
    });
  };
  
  render() {

    const { PracticeSelectedOption, userName, cost, suggested_employees, ManagerSelectedOption, employee_other_email, Filter_EmployeeSelectedOption, CountrySelectedOption, ChargedBySelectedOption } = this.state;

    const inputProps = {
      placeholder: 'Email Address',
      value: this.state.employee_email,
      onChange: this.handleEmailChange,
      className:"form-control",
      disabled:this.state.disableEmail
    };
    
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col className= "col-md-offset-1 col-md-10">
              <Card
                title=""
                category=""
                content={
                  <div>
                    <ToastContainer />
                    <Dialog ref={(component) => { this.dialog = component }} />
                    <div className="row">
                      <div className="col-md-offset-3 col-md-6">
                        <label>Select Practice</label>
                        <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                          <Select
                            value={PracticeSelectedOption}
                            onChange={(e) => this.handlePracticeChange(e)}
                            options={this.state.practices}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                          />
                        </FormGroup>
                        <FormInputs
                          ncols={["col-md-8", "col-md-4"]}
                          properties={[
                            {
                              label: "Employee Name",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Username",
                              value: userName,
                              onChange: (e) => this.handleUserNameChange(e)
                            },
                            {
                              label: "Employee Cost",
                              type: "number",
                              bsClass: "form-control",
                              placeholder: "Cost",
                              value: cost,
                              onChange: (e) => this.handleCostChange(e)
                            }
                          ]}
                        />
                        <label>LionLogin</label>
                        <FormGroup controlId="formControlsTextarea">
                            <Autosuggest
                              suggestions={suggested_employees}
                              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              onSuggestionSelected={this.suggestionSelected}
                              inputProps={inputProps}
                            />
                        </FormGroup>
                        <FormInputs
                          ncols={["col-md-12"]}
                          properties={[
                            {
                              label: "Email Address",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Email Address",
                              value: employee_other_email,
                              onChange: (e) => this.handleEmailAddressChange(e)
                            }
                          ]}
                        />
                        <label>Select Manager</label>
                        <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                          <Select
                            value={ManagerSelectedOption}
                            onChange={(e) => this.handleManagerChange(e)}
                            options={this.state.manager_employees}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                          />
                        </FormGroup>
                        <label>Select Market</label>
                        <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                          <Select
                            value={CountrySelectedOption}
                            onChange={(e) => this.handleCountryChange(e)}
                            options={this.state.countries}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                          />
                        </FormGroup>
                        <label>Charged By</label>
                        <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                          <Select
                            value={ChargedBySelectedOption}
                            onChange={(e) => this.handleChargedByChange(e)}
                            options={this.state.chargedBy}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                          />
                        </FormGroup>
                      </div>
                      
                      </div>
                      <div className="row">
                        <div className="col-md-offset-3 col-md-6">
                          <Button className="btn-success" onClick={this.submitRequest} disabled={this.state.employee_already_exist} fill>
                            <i className="fa fa-floppy-o" data-toggle="tooltip" title="Save" aria-hidden="true"></i>
                            <span>Save</span>
                          </Button>
                          <Button className="btn-clear ml-2" onClick={this.resetFields} data-toggle="tooltip" title="Clear" pullRight fill>
                          <i className="fa fa-eraser" aria-hidden="true"></i>
                          <span>Clear</span>
                        </Button>
                        </div> 
                      </div>
                    </div>
                }
              />
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col className= "col-md-offset-1 col-md-10">
              <Card
                title="Employee List"
                ctTableResponsive
                content={
                  <div>
                    <div className="row">
                      {/* <div className="col-lg-12">
                          <div className="col-md-2">
                            <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                              <Select
                                value={Filter_EmployeeSelectedOption}
                                onChange={(e) => this.handleEmployeeChangeFilter(e)}
                                options={this.state.all_employees}
                                // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body}
                                placeholder="Select Employee"
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-2">
                            <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                              <Select
                                value={Filter_EmployeeSelectedOption}
                                onChange={(e) => this.handleEmployeeChangeFilter(e)}
                                options={this.state.all_employees}
                                // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body}
                                placeholder="Select Manager"
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-2">
                            <Button className="btn-edit" fill data-toggle="tooltip" title="Search" onClick={() => this.prepareQueryObj()}>
                              <i className="fa fa-search" aria-hidden="true"></i>
                            </Button>
                            <Button className="btn-clear ml-2" fill data-toggle="tooltip" title="Reset" onClick={() => this.clearFilters()}>
                              <i className="fa fa-eraser" aria-hidden="true"></i>
                            </Button>
                        </div>
                      </div> */}
                    </div>
                    <div className="row">
                        
                        <div className="col-md-12">
                        <DataTable
                          title=""
                          className="daily-hours-table"
                          columns={employee_columns}
                          data={this.state.employees}
                          paginationRowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                          pagination
                        />
                        </div>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Employee;
