/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Select from 'react-select';
import { Card } from "components/Card/Card.jsx";

import {
  BASE_URL,
  fetchAllCountry,
  fetchDivisionOnCountry,
  fetchAllDivision,
  fetchEmployeesUnderManager,
  populateVerifyTable
} from "../helper/helper.jsx"
import DataTable from 'react-data-table-component';
import Button from "components/CustomButton/CustomButton.jsx";

import Cookies from 'js-cookie';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import moment from 'moment';
import {Radio, RadioGroup} from 'react-radio-group'

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Helmet from 'react-helmet';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from 'react-bootstrap-dialog'

import {
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";

import {
  fetchAllClients,
} from "../helper/helper.jsx"
import { VERIFY_TABLE_HEAD } from '../helper/data'

const axios = require('axios');
let userData = {}
if (Cookies.get('userData')) {
  userData = JSON.parse(Cookies.get('userData'))
}

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
let columns = VERIFY_TABLE_HEAD

const CommentComponent = ({ data }) => (
  <p style={{fontSize: '13px'}}>
    {data.comments == "" ? "No comment available" : data.comments}
  </p>
);

const conditionalRowStyles = [
  {
    when: row => row.status == 2,
    style: {
      backgroundColor: '#f8d7da',
      color: '#721c24',
    },
  },
  {
    when: row => row.status == 1,
    style: {
      backgroundColor: '#d4edda',
      color: '#155724',
    },
  },
  {
    when: row => row.status == 3,
    style: {
      backgroundColor: '#fff3cd',
      color: '#856404',
    },
  },
];


class VerifyHours extends Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      clients: [],
      employees: [],
      employee_selectedOption: null,
      selectedOption: null,
      queryData: {},
      unverifiedWorkLogs: [],
      logChecked: false,
      logSelectedToApprove: [],
      toggleCleared: false,
      DivisonSelectedOption: null,
      divisions: [],
      disableClient: true,
      loading: false,
      totalRows: 0,
      perPage: 10,
      CountrySelectedOption: null,
      countries: [],
      disableDivision: true,
      radioSelectedValue: '1'
    };
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }

  renderButtonHTML = (data) => {
    if ((data.status == 0) || (data.status == 3)) {
      return (
        <div>
          <Button className="btn-success" pullRight fill onClick={() => this.VerifyLog(data)}>
            Approve
          </Button>
          <Button className="btn-delete ml-2" pullRight fill onClick={() => this.RejectLog(data)}>
            Reject
          </Button>
        </div>
      )
    }
    else if (data.status == 2) {
      return (
        <div>
          <Button className="btn-success" pullRight fill onClick={() => this.VerifyLog(data)}>
            Approve
          </Button>
        </div>
      )
    }
    else if (data.status == 1) {
      return (
        <div>
          <Button className="btn-delete ml-2" pullRight fill onClick={() => this.RejectLog(data)}>
            Reject
          </Button>
        </div>
      )

    }
    
  }

  RejectLog = (data) => {
    this.dialog.show({
      body: 'Rejection Reason',
      prompt: Dialog.TextPrompt(),
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction((dialog) => {
          const result = dialog.value
          let url = BASE_URL + "/verifyLogs"

          axios.post(url, {
            data: [data],
            verify: 0,
            rejectedComment: (result == null || result == "") ? "" : result,
            token: userData.authToken
          })
          .then((response) => {
            this.setState(state => ({ toggleCleared: !state.toggleCleared }));
            this.resetFields()
            const { perPage } = this.state;
            this.setState({ loading: true });
            this.populateTable(this.state.queryData, userData["employee_id"], timeZone, 1, perPage, this.state.radioSelectedValue)
          })
          .catch(function (error) {
            console.log(error);
          });
        })
      ]
    })
  }


  VerifyLog = (data) => {
    this.dialog.show({
      title: 'Confirmation',
      body: 'Are you sure you want to verify this entry?',
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(() => {
          let url = BASE_URL + "/verifyLogs"

          axios.post(url, {
            data: [data],
            verify: 1,
            token: userData.authToken
          })
          .then((response) => {
            this.setState(state => ({ toggleCleared: !state.toggleCleared }));
            this.resetFields()
            const { perPage } = this.state;
            this.setState({ loading: true });
            this.populateTable(this.state.queryData, userData["employee_id"], timeZone, 1, perPage, this.state.radioSelectedValue)
          })
          .catch(function (error) {
            console.log(error);
          });
        })
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  VerifyLogs = (data) => {
    if (data.length == 0) {
      toast.error("Please select at least one log to verify.");
    }
    else {
      this.dialog.show({
        title: 'Confirmation',
        body: 'Are you sure you want to verify all selected entries?',
        actions: [
          Dialog.CancelAction(),
          Dialog.OKAction(() => {
            let url = BASE_URL + "/verifyLogs"

            axios.post(url, {
              data: data,
              verify: 1,
              token: userData.authToken
            })
              .then((response) => {
                this.setState(state => ({ toggleCleared: !state.toggleCleared }));
                this.resetFields()
                const { perPage } = this.state;
                this.setState({ loading: true });
                this.populateTable(this.state.queryData, userData["employee_id"], timeZone, 1, perPage, this.state.radioSelectedValue)
              })
              .catch(function (error) {
                console.log(error);
              });
          })
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        }
      })
    }

  }

  RejectLogs = (data) => {
    if (data.length == 0) {
      toast.error("Please select at least one log to verify.");
    }
    else {
      this.dialog.show({
        title: 'Confirmation',
        body: 'Rejection Reason',
        prompt: Dialog.TextPrompt(),
        actions: [
          Dialog.CancelAction(),
          Dialog.OKAction((dialog) => {
            const result = dialog.value
            let url = BASE_URL + "/verifyLogs"

            axios.post(url, {
              data: data,
              verify: 0,
              rejectedComment: (result == null || result == "") ? "" : result,
              token: userData.authToken
            })
              .then((response) => {
                this.setState(state => ({ toggleCleared: !state.toggleCleared }));
                this.resetFields()
                const { perPage } = this.state;
                this.setState({ loading: true });
                this.populateTable(this.state.queryData, userData["employee_id"], timeZone, 1, perPage, this.state.radioSelectedValue)
              })
              .catch(function (error) {
                console.log(error);
              });
          })
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        }
      })
    }

  }

  prepareQueryObj() {
    let temp = {}
    if (this.state.employee_selectedOption !== null) {
      temp.employee_id = this.state.employee_selectedOption.value
    }
    if (this.state.CountrySelectedOption !== null) {
      temp.country_id = this.state.CountrySelectedOption.value
    }
    if (this.state.DivisonSelectedOption !== null) {
      temp.division_id = this.state.DivisonSelectedOption.value
    }
    if (this.state.selectedOption !== null) {
      temp.client_id = this.state.selectedOption.value
    }
    if (this.state.from !== undefined) {
      temp.from = this.state.from
    }
    if (this.state.to !== undefined) {
      temp.to = this.state.to
    }

    this.setState({
      queryData: temp
    }, () => {
      const { perPage } = this.state;
      this.setState({ loading: true });
      this.populateTable(this.state.queryData, userData["employee_id"], timeZone, 1, perPage, this.state.radioSelectedValue)
    });

  }

  handleRadioChange = (value) => {
    this.setState({radioSelectedValue: value}, () => {
      const { perPage } = this.state;
      this.setState({ loading: true });
      this.populateTable(this.state.queryData, userData["employee_id"], timeZone, 1, perPage, this.state.radioSelectedValue)
    });
    
  }

  handleEmployeeChange = (e) => {
    let temp = e.value
    this.setState({
      employee_selectedOption: e,
    });
  };

  populateTable(queryData, emp_id, timeZone, page, perPage, viewType) {
    populateVerifyTable(queryData, emp_id, timeZone, page, perPage, viewType).then((data) => {
      let result_data = data.results.record
      result_data.map((value, index) => {
        result_data[index].action = this.renderButtonHTML(value)
      })
      this.setState({
        unverifiedWorkLogs: data.results.record,
        totalRows: data.results.total,
        loading: false,
      })
    })
  }



  componentDidMount() {
    fetchAllCountry().then((data) => {
      this.setState({
        countries: data[0].data.result
      })
    }).catch(err => console.log(err))

    fetchEmployeesUnderManager(userData["employee_id"]).then((data) => {
      this.setState({
        employees: data.results
      })
    })

    const { perPage } = this.state;
    this.setState({ loading: true });

    this.populateTable(this.state.queryData, userData["employee_id"], timeZone, 1, perPage, this.state.radioSelectedValue)
  }

  handleCountryChange = (e) => {
    let temp = e.value
    this.setState({
      CountrySelectedOption: e,
    }, () => {
      fetchDivisionOnCountry(temp).then((data) => {
        this.setState({
          divisions: data.result,
          disableDivision: (temp != "") ? false : true
        })
      }).catch(err => console.log(err))
    });
  };

  handleDivisonChange = (e) => {
    let temp = e.value
    this.setState({
      DivisonSelectedOption: e,
    }, () => {
      fetchAllClients(temp, this.state.CountrySelectedOption.value).then((data) => {
        this.setState({
          "clients": data[0].data.result,
          disableClient: (temp != "") ? false : true
        })
      }).catch(err => console.log(err))
    });
  };

  handleClientChange = (e) => {
    this.setState({
      selectedOption: e,
    });
  };

  checkLog = state => {
    this.setState({ logSelectedToApprove: state.selectedRows });
  };

  resetFields = () => {
    this.setState({
      from: undefined,
      to: undefined,
      employee_selectedOption: '',
      selectedOption: '',
      queryData: {},
      unverifiedWorkLogs: [],
      logSelectedToApprove: [],
      DivisonSelectedOption: '',
      disableClient: true,
    });
  }

  clearFilters = () => {
    window.location.reload();
  }

  handlePageChange = async page => {
    const { perPage } = this.state;
    this.setState({ loading: true });
    this.populateTable(this.state.queryData, userData["employee_id"], timeZone, page, perPage, this.state.radioSelectedValue)
  }

  handlePerRowsChange = async (perPage, page) => {
    this.setState({ loading: true });
    this.populateTable(this.state.queryData, userData["employee_id"], timeZone, page, perPage, this.state.radioSelectedValue)
    this.setState({
      perPage,
    });
  }


  render() {
    const { 
      from, to, selectedOption, employee_selectedOption, CountrySelectedOption, 
      toggleCleared, DivisonSelectedOption, loading, totalRows } = this.state;
    const modifiers = { start: from, end: to };

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Approve Hours"
                category=''
                ctTableResponsive
                content={
                  <div>
                    <ToastContainer />
                    <Dialog ref={(component) => { this.dialog = component }} />
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="col-md-2">
                          <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                            <label className="form-label">Employee</label>
                            <Select
                              value={employee_selectedOption}
                              onChange={(e) => this.handleEmployeeChange(e)}
                              options={this.state.employees}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-2">
                          <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                            <label className="form-label">Country</label>
                            <Select
                              value={CountrySelectedOption}
                              onChange={(e) => this.handleCountryChange(e)}
                              options={this.state.countries}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-2">
                          <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                            <label className="form-label">Division</label>
                            <Select
                              value={DivisonSelectedOption}
                              onChange={(e) => this.handleDivisonChange(e)}
                              options={this.state.divisions}
                              isDisabled={this.state.disableDivision}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-2">
                          <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                            <label className="form-label">Client</label>
                            <Select
                              value={selectedOption}
                              onChange={(e) => this.handleClientChange(e)}
                              options={this.state.clients}
                              isDisabled={this.state.disableClient}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-4">
                          <label className="form-label">Date Range</label>

                          <div className="InputFromTo">
                            <DayPickerInput
                              value={from}
                              placeholder="From"
                              format="LL"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                selectedDays: [from, { from, to }],
                                disabledDays: { after: to },
                                toMonth: to,
                                modifiers,
                                numberOfMonths: 2,
                                onDayClick: () => this.to.getInput().focus(),
                              }}
                              onDayChange={this.handleFromChange}
                            />{' '}
                            —{' '}
                            <span className="InputFromTo-to">
                              <DayPickerInput
                                ref={el => (this.to = el)}
                                value={to}
                                placeholder="To"
                                format="LL"
                                formatDate={formatDate}
                                parseDate={parseDate}
                                dayPickerProps={{
                                  selectedDays: [from, { from, to }],
                                  disabledDays: { before: from },
                                  modifiers,
                                  month: from,
                                  fromMonth: from,
                                  numberOfMonths: 2,
                                }}
                                onDayChange={this.handleToChange}
                              />
                            </span>
                            <Helmet>
                              <style>{`
                              .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                                background-color: #f0f8ff !important;
                                color: #4a90e2;
                              }
                              .InputFromTo .DayPicker-Day {
                                border-radius: 0 !important;
                              }
                              .InputFromTo .DayPickerInput input{
                                max-width: 140px !important;
                              }
                              .InputFromTo-to .DayPickerInput input{
                                max-width: 140px !important;
                              }
                              .InputFromTo .DayPicker-Day--start {
                                border-top-left-radius: 50% !important;
                                border-bottom-left-radius: 50% !important;
                              }
                              .InputFromTo .DayPicker-Day--end {
                                border-top-right-radius: 50% !important;
                                border-bottom-right-radius: 50% !important;
                              }
                              .InputFromTo .DayPickerInput-Overlay {
                                width: 350px;
                              }
                              .InputFromTo-to .DayPickerInput-Overlay {
                                margin-left: -198px;
                              }
                            `}</style>
                            </Helmet>
                          </div>

                        </div>

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-offset-10 col-md-2">
                          <Button className="btn-edit mt-24" fill data-toggle="tooltip" title="Search" onClick={() => this.prepareQueryObj()}>
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </Button>
                          <Button className="btn-clear ml-2 mt-24" fill data-toggle="tooltip" title="Reset" onClick={() => this.clearFilters()}>
                            <i className="fa fa-eraser" aria-hidden="true"></i>
                          </Button>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-12">
                          <Button className="btn-success" fill onClick={() => this.VerifyLogs(this.state.logSelectedToApprove)}>
                            Approve Selected
                          </Button>
                          <Button className="btn-delete ml-2" fill onClick={() => this.RejectLogs(this.state.logSelectedToApprove)}>
                            Reject Selected
                          </Button>
                        </div>
                        
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="col-md-12">
                        <RadioGroup name="fruit" selectedValue={this.state.radioSelectedValue} onChange ={(e) => this.handleRadioChange(e)}>
                          <label className="radio-label"><Radio value="1" /> Non-Approved hours</label>
                          <label className="radio-label"><Radio value="2" /> Approved hours</label>
                          <label className="radio-label"><Radio value="3" /> Rejected hours</label>
                        </RadioGroup>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <DataTable
                          title=""
                          columns={columns}
                          data={this.state.unverifiedWorkLogs}
                          pagination
                          className="daily-hours-table"
                          noHeader
                          defaultSortField="start_date"
                          selectableRows
                          persistTableHead
                          clearSelectedRows={toggleCleared}
                          onSelectedRowsChange={this.checkLog}
                          expandableRows
                          expandableRowsComponent={<CommentComponent />}
                          progressPending={loading}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={this.handlePerRowsChange}
                          onChangePage={this.handlePageChange}
                          conditionalRowStyles={conditionalRowStyles}
                          paginationRowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default VerifyHours;
