
import moment from 'moment';
import Cookies from 'js-cookie';

let userData = {}
if (Cookies.get('userData')) {
  userData = JSON.parse(Cookies.get('userData'))
}

export let BASE_URL = "https://pmedtimesheetb.azurewebsites.net";
if (window.location.origin.indexOf('localhost') > -1) {
    BASE_URL = "http://localhost:3001";
}

export function calculateRequiredWorkType(empId, timeZone, dateObj, req_limit) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');

        axios.post(BASE_URL + '/getTotalHoursLogged', {
            data: dateObj,
            employeeId: empId,
            timeZone: timeZone,
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        let data = values[0].data.results
        let timeCount = "00:00"
        data.map((value, index) => {
            timeCount = moment.duration(value.work_time).add(timeCount)
        })
        timeCount = moment.duration(req_limit).subtract(timeCount)

        let minutes = moment.duration(timeCount.asMilliseconds()).minutes();
        let hours = Math.trunc(moment.duration(timeCount.asMilliseconds()).asHours());
        let timeCountStatus = {}

        if (hours > 0) {
            timeCountStatus.type = 1
        }
        else {
            timeCountStatus.type = 0
        }
        timeCountStatus.value = Math.abs(hours) + ':' + moment(Math.abs(minutes), 'minutes').format('mm')
        return timeCountStatus
    });
}

export function fetchEmployeesUnderManager(userId) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');

        axios.post(BASE_URL + '/fetchEmployeeUnderManager', {
            data: userId,
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values[0].data;
    });

}

export function fetchAllLogsDetail(userId, date, timeZone) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');

        axios.post(BASE_URL + '/fetchLogsDetail', {
            userId: userId,
            curr_date: date,
            timeZone: timeZone,
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values[0].data;
    });

}

export function populateVerifyTable(data = {}, userId, timeZone, page, perPage, viewType) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/fetchToVerifyData', {
            data: data,
            userId: userId,
            timeZone: timeZone,
            page: page,
            perPage: perPage,
            viewType: viewType,
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values[0].data;
    });
}

export function GetEntries(data) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');

        axios.post(BASE_URL + '/fetchFilledDates', {
            data: data,
            token: userData.authToken,
            userLocale: userData.locale
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values[0].data;
    });
}

export function fetchDataById(data) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/getweekdatabyid', {
            data: data,
            token: userData.authToken,
            userLocale: userData.locale
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values[0].data;
    });
}

export function fetchDailyDataById(data) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/getdailylogsbyid', {
            data: data,
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values;
    });
}

export function getDataForDuplicate(data) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');
        let route = '/getweekdataforduplicate'
        if (data.LogEntryType == "daily") {
            route = '/getdailydataforduplicate'
        }
        axios.post(BASE_URL + route, {
            data: data,
            token: userData.authToken,
            userLocale: userData.locale
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values[0].data;
    });
}

export function fetchAllClients(divisionId, country_id) {
    let promiseClient = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/fetchallclients', { 
            division_id: divisionId, 
            country_id: country_id,
            token: userData.authToken 
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promiseClient]).then(function (values) {
        return values;
    });
}

export function fetchAllDivision() {
    let promiseClient = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/fetchalldivision', {
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promiseClient]).then(function (values) {
        return values;
    });
}

export function fetchDivisionList() {
    let promiseClient = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/fetchDivisionList', {
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promiseClient]).then(function (values) {
        return values;
    });
}

export function fetchCountryList() {
    let promiseClient = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/fetchCountryList', {
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promiseClient]).then(function (values) {
        return values;
    });
}
export function fetchPracticeList(userData) {
    let promiseClient = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/fetchPracticeList', {
            token: userData.authToken,
            userData: userData
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promiseClient]).then(function (values) {
        return values;
    });
}
export function fetchEmployeeList(userData) {
    let promiseClient = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/fetchEmployeeList', {
            token: userData.authToken,
            userData: userData
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promiseClient]).then(function (values) {
        return values;
    });
}


export function fetchClients() {
    let promiseClient = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/fetchClients', {
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promiseClient]).then(function (values) {
        return values;
    });
}

export function fetchClientsWithDetails(page, perPage) {
    let promiseClient = new Promise((resolve, reject) => {
        const axios = require('axios');
        let api_url = BASE_URL + `/clientRecordByPage?page=${page}&per_page=${perPage}&delay=1`
        axios.get(api_url).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promiseClient]).then(function (values) {
        return values;
    });
}

export function fetchDivisionOnCountry(country_id) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/fetchdivisionbycountry', {
            country_id: country_id,
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values[0].data;
    });
}

export function fetchAllChargedBy() {
    let promiseClient = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/fetchallchargedby', {
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promiseClient]).then(function (values) {
        return values;
    });
}

export function fetchAllCountry() {
    let promiseClient = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/fetchallcountry', {
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promiseClient]).then(function (values) {
        return values;
    });
}

export function checkDivisionAvailability (obj) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/checkDivisionAvailability', {
            data: obj,
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values[0].data;
    });
}
export function checkClientAvailability (obj) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/checkClientAvailability', {
            data: obj,
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values[0].data;
    });
}

export function fetchWeeklyById(employee_id, queryData = {}, timeZone = "") {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/getweeklylogsbyid', {
            employee_id: employee_id,
            token: userData.authToken,
            queryData: queryData,
            timeZone: timeZone
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values[0].data;
    });
}
export function fetchMonthlyById(employee_id, queryData = {}, timeZone = "") {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/getmonthlylogsbyid', {
            employee_id: employee_id,
            token: userData.authToken,
            queryData: queryData,
            timeZone: timeZone
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values[0].data;
    });
}

export function authorizeUser(userEmail) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/authorizeuser', {
            userEmail: userEmail,
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values;
    });
}

export function fetchUserToVerifyLogs(employeeID) {
    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');
        axios.post(BASE_URL + '/fetchusertoverifylogs', {
            employee_id: employeeID,
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })
    })
    return Promise.all([promise1]).then(function (values) {
        return values;
    });
}

export function checkDisableDateToSelectedClient(employeeID, clientID) {

    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');

        axios.post(BASE_URL + '/checkavailabledays', {
            client_id: clientID,
            employee_id: employeeID,
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })

    })

    return Promise.all([promise1]).then(function (values) {
        return values;
    });
}

export function checkDisableDateRangeToSelectedClient(employeeID, clientID, entryType) {

    let promise1 = new Promise((resolve, reject) => {
        const axios = require('axios');

        axios.post(BASE_URL + '/checkavailabledaysrange', {
            client_id: clientID,
            employee_id: employeeID,
            entryType: entryType,
            token: userData.authToken
        }).then(function (response) {
            if (response !== undefined) {
                resolve(response);
            }
            else {
                reject([])
            }
        })

    })

    return Promise.all([promise1]).then(function (values) {
        return values;
    });

}


