/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl
} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  BASE_URL,
} from "../helper/helper.jsx"

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import Cookies from 'js-cookie';
const axios = require('axios');

let userData = {}
if (Cookies.get('userData')) {
  userData = JSON.parse(Cookies.get('userData'))
}

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.state = {
      subject: '',
      message: undefined,
    };
  }

  handleSubjectChange(event) {
    let fieldVal = event.target.value;
    this.setState({
      "subject": fieldVal,
    });
  }
  handleMessageChange(event) {
    let fieldVal = event.target.value;
    this.setState({
      "message": fieldVal,
    });
  }

  submitRequest = () => {
    if (this.state.subject == "") {
      toast.error("Please provide subject.");
    }
    else if (this.state.message == undefined || this.state.message == "") {
      toast.error("Please provide message.");
    }
    else {
      let url = BASE_URL + "/submitMessage"
      
      let req_data = {
        "employee_email": (userData["employee_email"] !== undefined && userData["employee_email"] !== "") ? userData["employee_email"] : "",
        "subject": this.state.subject,
        "message": this.state.message,
        "timeZone": timeZone
      }
      axios.post(url, {
        data: req_data,
        token: userData.authToken
      }).then((response) => {
        this.resetFields();
        toast.success("Message Submitted. We will get back to you soon!");
      })

    }
  }

  resetFields = () => {
    this.setState({
      subject: '',
      message: "",
    });
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col className= "col-md-offset-2 col-md-8">
              <Card
                title="Contact Us"
                category="Thank you for your interest in contacting us, please fill in the form below and we will get back to you soon."
                content={
                  <div>
                    <ToastContainer />
                    <label>Subject</label>
                    <FormGroup controlId="formControlsTextarea">
                      <FormControl
                        type="text"
                        bsClass="form-control"
                        placeholder="Please provide subject"
                        value={this.state.subject}
                        onChange={this.handleSubjectChange.bind(this)}
                      />
                    </FormGroup>
                    <label>Message</label>
                    <FormGroup controlId="formControlsTextarea">
                      <FormControl
                        rows="5"
                        componentClass="textarea"
                        bsClass="form-control"
                        placeholder="Here can be your description"
                        value={this.state.message}
                        onChange={this.handleMessageChange.bind(this)}
                      />
                  </FormGroup>
                  <Button className="btn-success" onClick={this.submitRequest} data-toggle="tooltip" title="Submit" fill>
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                    <span>Submit</span>
                  </Button>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ContactUs;
