import React, { Component } from "react";
import Select from 'react-select';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';

import Autosuggest from 'react-autosuggest';

import {
  fetchCountryList,
  fetchAllCountry,
  BASE_URL,
} from "../helper/helper.jsx"

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Dialog from 'react-bootstrap-dialog'

import Cookies from 'js-cookie';
import { COUNTRY_TABLE_HEAD } from '../helper/data'
const axios = require('axios');

let country_columns = COUNTRY_TABLE_HEAD
let userData = {}
if (Cookies.get('userData')) {
  userData = JSON.parse(Cookies.get('userData'))
}

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

class Country extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      filter_countries: [],
      all_countries: [],
      country: '',
      country_already_exist: false,
      oldRecord: {}
    };
  }

  componentDidMount() {
    this.fetchAllCountryList()
  }

  editCountry = (data) => {
    this.setState({
      oldRecord: data,
      country: data.country_name, 
      country_already_exist: true,
    })
  }

  deleteCountryLink = (data) => {
    this.dialog.show({
      title: 'Confirmation',
      body: 'Are you sure you want to delete this country link?',
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(() => {
          let url = BASE_URL + "/deleteCountryLink"
          data.employee_id = userData["employee_id"]
          axios.post(url, {
            data: data,
            token: userData.authToken
          })
            .then((response) => {
              this.fetchAllCountryList()
              this.resetFields();
            })
            .catch(function (error) {
              console.log(error);
            });
        })
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  renderButtonHTML = (data) => {
    return (
      <div className="table-btn-holder">
        <Button className="btn-edit" fill data-toggle="tooltip" title="Edit" onClick={() => this.editCountry(data)}>
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </Button>
        <Button className="btn-delete ml-2" fill data-toggle="tooltip" title="Delete" onClick={() => this.deleteCountryLink(data)}>
          <i className="fa fa-trash" aria-hidden="true"></i>
        </Button>
      </div>
    )
  }

  fetchAllCountryList () {
    fetchCountryList().then((data) => {
      let all_country_list = data[0].data.results.country_record
      all_country_list.map((value, index) => {
        if (value.total_record == 0) {
          all_country_list[index].action = this.renderButtonHTML(value)
        }
      })
      this.setState({
        filter_countries: data[0].data.results.country,
        all_countries: all_country_list
      })
    }).catch(err => console.log(err))
  }


  submitRequest = () => {
    if (this.state.country == "") {
      toast.error("Please provide country.");
    }
    else {
      let url = BASE_URL + "/insertCountry"
      
      let req_data = {
        "country": this.state.country,
        "oldRecord": this.state.oldRecord
      }
      axios.post(url, {
        data: req_data,
        token: userData.authToken
      }).then((response) => {
        this.resetFields();
        toast.success("Country Created!");
        this.fetchAllCountryList()
      })

    }
  }

  handleCountry = (e) => {
    this.setState({country: e.target.value})
  }

  resetFields = () => {
    this.setState({
      country: ''
    });
  }

  getSuggestions = value => {
    let filter_countries = this.state.filter_countries
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
   
    let foundCountry = inputLength === 0 ? [] : filter_countries.filter(country =>
      country.label.toLowerCase().slice(0, inputLength) === inputValue
    );
    this.setState({
      country_already_exist: foundCountry.length > 0 ? true : false
    })

    return foundCountry
  };


  getSuggestionValue = suggestion => suggestion.label;

  renderSuggestion = suggestion => suggestion.label;

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      countries: this.getSuggestions(value)
    });
  };
 
  onSuggestionsClearRequested = () => {
    this.setState({
      countries: []
    });
  };
  suggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.setState({country: suggestionValue})
  }

  render() {
    const { value, countries } = this.state;

    const inputProps = {
      placeholder: 'country name',
      value: this.state.country,
      onChange: this.handleCountry,
      className:"form-control"
    };

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col className= "col-md-offset-2 col-md-8">
              <Card
                title=""
                category=""
                content={
                  <div>
                    <ToastContainer />
                    <Dialog ref={(component) => { this.dialog = component }} />
                    <div className="row">
                      <div className="col-md-offset-2 col-md-6">
                        <label>Please Enter Country Name</label>
                        <FormGroup controlId="formControlsTextarea">
                        <Autosuggest
                          suggestions={countries}
                          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestion={this.renderSuggestion}
                          onSuggestionSelected={this.suggestionSelected}
                          inputProps={inputProps}
                        />
                        </FormGroup>
                        {(this.state.country_already_exist !== undefined && this.state.country_already_exist) ? <ControlLabel style={{ color: '#B00020' }}>* Country Already Exists</ControlLabel> : <ControlLabel></ControlLabel>}
                      </div>
                      <div className="col-md-2">
                        <div className="mg-tp-25">
                          <Button className="btn-success" onClick={this.submitRequest} disabled={this.state.country_already_exist} fill>
                            <i className="fa fa-floppy-o" data-toggle="tooltip" title="Save" aria-hidden="true"></i>
                            <span>Save</span>
                          </Button>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col  className= "col-md-offset-2 col-md-8">
              <Card
                title="Countries List"
                ctTableResponsive
                content={
                  <div className="row">
                      <div className="col-md-12">
                      <DataTable
                        title=""
                        className="daily-hours-table"
                        columns={country_columns}
                        data={this.state.all_countries}
                        paginationRowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                        pagination
                      />
                      </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Country;
