/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Grid, Row, Col } from "react-bootstrap";

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import Cookies from 'js-cookie';
import { Card } from "components/Card/Card.jsx";

import {
  fetchAllLogsDetail,
} from "../helper/helper.jsx"
import { calendarFormat } from "moment";

let userData = {}
if (Cookies.get('userData')) {
  userData = JSON.parse(Cookies.get('userData'))
}

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logsDetail: [],
    };
  }
  calendarRef = React.createRef()

  createLegend() {
    var legend = [];
    var json = {
      names: ["Exact 8 hours", "Above 8 hours", "Below 8 hours"],
      types: ["exact", "above", "below"]
    };

    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i] + " ");

    }
    return legend;
  }

  componentDidMount() {
    fetchAllLogsDetail(userData["employee_id"], new Date(), timeZone).then((data) => {
      let res = data.results
      res.map((value, index) => {
        if (value.timeObj.hours == 8 && value.timeObj.minutes == '00') {
          res[index].color = '#00B0A3'
        } else if (value.timeObj.hours < 8) {
          res[index].color = '#D93D7A'
        } else if (value.timeObj.hours > 8 || (value.timeObj.hours >= 8 && value.timeObj.minutes !== "00" )) {
          res[index].color = '#16ABE0'
        }
      })

      this.setState({
        logsDetail: res,
      })
    }).catch(err => console.log(err))
  }

  fetchNext (e) {
    let calendarApi = this.calendarRef.current.getApi()
    calendarApi.next()
    var elems = document.getElementsByClassName("fc-next-button");
    for(var i = 0; i < elems.length; i++) {
        elems[i].disabled = true;
    }
    let next_date = calendarApi.getDate()
    fetchAllLogsDetail(userData["employee_id"], next_date, timeZone).then((data) => {
      let res = data.results
      res.map((value, index) => {
        if (value.timeObj.hours == 8 && value.timeObj.minutes == '00') {
          res[index].color = '#00B0A3'
        } else if (value.timeObj.hours < 8) {
          res[index].color = '#D93D7A'
        } else if (value.timeObj.hours > 8 || (value.timeObj.hours >= 8 && value.timeObj.minutes !== "00" )) {
          res[index].color = '#16ABE0'
        }
      })

      this.setState({
        logsDetail: res,
      })
      var elems = document.getElementsByClassName("fc-next-button");
      for(var i = 0; i < elems.length; i++) {
          elems[i].disabled = false;
      }
    }).catch(err => console.log(err))
  }

  fetchPrev (e) {
    let calendarApi = this.calendarRef.current.getApi()
    calendarApi.prev()
    var elems = document.getElementsByClassName("fc-prev-button");
    for(var i = 0; i < elems.length; i++) {
        elems[i].disabled = true;
    }
    let prev_date = calendarApi.getDate()
    fetchAllLogsDetail(userData["employee_id"], prev_date, timeZone).then((data) => {
      let res = data.results
      res.map((value, index) => {
        if (value.timeObj.hours == 8 && value.timeObj.minutes == '00') {
          res[index].color = '#00B0A3'
        } else if (value.timeObj.hours < 8) {
          res[index].color = '#D93D7A'
        } else if (value.timeObj.hours > 8 || (value.timeObj.hours >= 8 && value.timeObj.minutes !== "00" )) {
          res[index].color = '#16ABE0'
        }
      })

      this.setState({
        logsDetail: res,
      })
      var elems = document.getElementsByClassName("fc-prev-button");
      for(var i = 0; i < elems.length; i++) {
          elems[i].disabled = false;
      }
    }).catch(err => console.log(err))
  }

  fetchToday (e) {
    let calendarApi = this.calendarRef.current.getApi()
    calendarApi.today()
    fetchAllLogsDetail(userData["employee_id"], new Date(), timeZone).then((data) => {
      let res = data.results
      res.map((value, index) => {
        if (value.timeObj.hours == 8 && value.timeObj.minutes == '00') {
          res[index].color = '#00B0A3'
        } else if (value.timeObj.hours < 8) {
          res[index].color = '#D93D7A'
        } else if (value.timeObj.hours > 8 || (value.timeObj.hours >= 8 && value.timeObj.minutes !== "00" )) {
          res[index].color = '#16ABE0'
        }
      })

      this.setState({
        logsDetail: res,
      })
    }).catch(err => console.log(err))
  }

  render() {
    const { logsDetail } = this.state;

    const EventDetail = ({ event, el }) => {
      const content = <div>{event.title}<div>{event.description}</div></div>;
      ReactDOM.render(content, el);
      return el;
    };

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title=""
                category={
                  <span className="legend">{this.createLegend()}</span>
                }
                ctTableResponsive
                content={
                  <FullCalendar
                    defaultView="dayGridMonth"
                    fixedWeekCount= {false}
                    plugins={[dayGridPlugin]}
                    events={logsDetail}
                    customButtons={{
                      next: {
                          text: 'Next',
                          click: (e) => this.fetchNext(e)
                      },
                      prev: {
                        text: 'Prev',
                        click: (e) => this.fetchPrev(e)
                      },
                      today: {
                        text: 'Today',
                        click: (e) => this.fetchToday(e)
                      }
                    }}
                    header={{
                      right: 'prev,next, today',
                    }}
                    eventRender={EventDetail}
                    ref={this.calendarRef}
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
