
import React, { Component } from "react";
import Select from 'react-select';
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import {
  fetchAllCountry,
  fetchDivisionOnCountry,
  fetchAllClients,
  fetchMonthlyById,
  BASE_URL,
  GetEntries,
  fetchDataById,
  getDataForDuplicate,
  calculateRequiredWorkType
} from "../helper/helper.jsx"

import moment from 'moment';
import businessDays from 'moment-business-days';
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Helmet from 'react-helmet';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import DataTable from 'react-data-table-component';
import Button from "components/CustomButton/CustomButton.jsx";
import {
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from 'react-bootstrap-dialog'

import Cookies from 'js-cookie';
import { TABLE_HEAD, TIME_OPTIONS } from '../helper/data'
const axios = require('axios');
let userData = {}
if (Cookies.get('userData')) {
  userData = JSON.parse(Cookies.get('userData'))
}
let columns = TABLE_HEAD
let timeOptions = TIME_OPTIONS
let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

function getWeekDays(weekStart) {
  const days = [weekStart];
  let loopLimit = moment(weekStart, "YYYY-MM-DD").daysInMonth()
  for (let i = 1; i < loopLimit; i += 1) {
    days.push(
      moment(weekStart)
        .add(i, 'days')
        .toDate()
    );
  }
  return days;
}

function getWeekRange(date) {
  return {
    from: moment(date)
      .startOf('month')
      .toDate(),
    to: moment(date)
      .endOf('month')
      .toDate(),
  };
}

const CommentComponent = ({ data }) => (
  <div>
    <p style={{fontSize: '13px'}}>
      {data.comments == "" ? "No comment available" : data.comments}
    </p> 
    <p style={(data.rejected_comment != null) ? {display: 'block', fontSize: '13px'} : {display: 'none'}}>
      {data.rejected_comment == "" ? "No rejected comment available" : "Rejected Comment: " + data.rejected_comment}
    </p>
  </div>
);

const conditionalRowStyles = [
  {
    when: row => row.noRejectedDays == 0,
    style: {
      backgroundColor: '#f8d7da',
      color: '#721c24',
    },
  },
];

class MonthlyLog extends Component {
  constructor(props) {
    super(props);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      CountrySelectedOption: null,
      DivisonSelectedOption: null,
      selectedOption: null,
      textArea: undefined,
      workLogs: [],
      hoverRange: undefined,
      selectedDays: [],
      clients: [],
      disableDays: true,
      userSelectedDays: [],
      weekendsStatus: '',
      hoursForAll: '',
      initialDate: new Date(),
      divisions: [],
      disableClient: true,
      disableSaveButton: false,
      countries: [],
      disableDivision: true,
      queryData: {},
      from: undefined,
      to: undefined,
      disableClientFilter: true,
      disableDivisionFilter: true,
      Filter_selectedOption: null,
      Filter_DivisonSelectedOption: null,
      Filter_CountrySelectedOption: null,
    };
  }

  resetFields = () => {
    this.setState({
      CountrySelectedOption: null,
      DivisonSelectedOption: null,
      selectedOption: null,
      selectedDays: [],
      textArea: "",
      userSelectedDays: [],
      disableDays: true,
      totalHoursAvailed: '',
      initialDate: new Date(),
      weekendsStatus: '',
      hoursForAll: '',
      clients: [],
      disableClient: true,
      disableSaveButton: false,
      disableDivision: true,
    });
  }

  clearFilters = () => {
    this.setState({ 
      queryData: {},
      from: undefined,
      to: undefined,
      Filter_selectedOption: null,
      Filter_DivisonSelectedOption: null,
      Filter_CountrySelectedOption: null, 
    });
    this.getLatestData()
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }

  getLoggedText = (data) => {
    let temp_str = ""
    if (data.type == 1) {
      temp_str = "Hours remaining for the selected month: " + data.value
    }
    else {
      temp_str = "You have overtime hours within this month: " + data.value
    }
    return temp_str
  }

  handleDayChange = (date, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    let reqObj = {
      'weekDays': getWeekRange(date),
      'selected_country': this.state.CountrySelectedOption.value,
      'selected_division': this.state.DivisonSelectedOption.value,
      'selected_client': this.state.selectedOption.value,
      'employee_id': userData["employee_id"],
      'LogEntryType': 'monthly',
      'timeZone': timeZone,
      'userWorkTime':this.state.userSelectedDays
    }
    GetEntries(reqObj).then((data) => {
      let totalHoursInMonth = (businessDays(reqObj.weekDays.from, 'DD-MM-YYYY').monthBusinessDays()).length
      totalHoursInMonth = totalHoursInMonth * 8
      totalHoursInMonth = totalHoursInMonth + ":00"

      calculateRequiredWorkType(userData["employee_id"], timeZone, getWeekRange(date), totalHoursInMonth).then((data) => {
        let temp_str = this.getLoggedText(data)
        this.setState({
          totalHoursAvailed: temp_str
        })
      })

      this.setState({
        userSelectedDays: data.results,
        textArea: data.results[0].comments,
      })
    }).catch(err => console.log(err))
    this.setState({
      selectedDays: getWeekDays(getWeekRange(date).from),
    });
  };

  handleDayEnter = date => {
    this.setState({
      hoverRange: getWeekRange(date),
    });
  };

  handleDayLeave = () => {
    this.setState({
      hoverRange: undefined,
    });
  };

  renderButtonHTML = (data) => {
    if (data.allDataApproved == 0) {
      return (
        <div className="table-btn-holder">
          <Button className="btn-edit" fill data-toggle="tooltip" title="Edit" onClick={() => this.editWorkLog(data)}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </Button>
          <Button className="btn-duplicate ml-2 mr-2" fill data-toggle="tooltip" title="Duplicate" onClick={() => this.duplicateWorkLog(data)}>
            <i className="fa fa-files-o" aria-hidden="true"></i>
          </Button>
          <Button className="btn-delete" fill data-toggle="tooltip" title="Delete" onClick={() => this.deleteWorkLog(data)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Button>
        </div>
      )
    }
    else {
      return (
        <div className="table-btn-holder">
          <Button className="btn-duplicate mr-2" fill data-toggle="tooltip" title="Duplicate" onClick={() => this.duplicateWorkLog(data)}>
            <i className="fa fa-files-o" aria-hidden="true"></i>
          </Button>
        </div>
        )
    }
  }

  componentDidMount() {
    this.getLatestData()
    fetchAllCountry().then((data) => {
      this.setState({
        countries: data[0].data.result
      })
    }).catch(err => console.log(err))
  }

  resetDivision = () => {
    this.setState({
      DivisonSelectedOption: null,
      disableDivision: true,
      divisions: [],
    })
  }

  resetClient = () => {
    this.setState({
      clients: [],
      disableClient: true,
      selectedOption: null
    })
  }

  getLatestData(queryData = {}) {
    fetchMonthlyById(userData["employee_id"], queryData, timeZone).then((data) => {
      let result_data = data.results
      result_data.map((value, index) => {
        result_data[index].action = this.renderButtonHTML(value)
      })
      this.setState({
        workLogs: result_data
      })
    })
  }

  prepareQueryObj() {
    let temp = {}
    if (this.state.Filter_CountrySelectedOption !== null) {
      temp.country_id = this.state.Filter_CountrySelectedOption.value
    }
    if (this.state.Filter_DivisonSelectedOption !== null) {
      temp.division_id = this.state.Filter_DivisonSelectedOption.value
    }
    if (this.state.Filter_selectedOption !== null) {
      temp.client_id = this.state.Filter_selectedOption.value
    }
    if (this.state.from !== undefined) {
      temp.from = this.state.from
    }
    if (this.state.to !== undefined) {
      temp.to = this.state.to
    }

    this.setState({
      queryData: temp
    }, () => {
      
      this.getLatestData(this.state.queryData)
    });

  }

  handleCountryChange = (e) => {
    this.resetDivision();
    this.resetClient();
    let temp = e.value
    this.setState({
      CountrySelectedOption: e,
    }, () => {
      fetchDivisionOnCountry(temp).then((data) => {
        this.setState({
          divisions: data.result,
          disableDivision: (temp != "") ? false : true
        })
      }).catch(err => console.log(err))
    });
  };


  handleDivisonChange = (e) => {
    let temp = e.value
    this.setState({
      DivisonSelectedOption: e,
    }, () => {
      fetchAllClients(temp, this.state.CountrySelectedOption.value).then((data) => {
        this.setState({
          clients: data[0].data.result,
          disableClient: (temp != "") ? false : true
        })
      }).catch(err => console.log(err))
    });
  };

  handleClientChange = (e) => {
    this.setState({
      selectedOption: e,
      disableDays: (e.value != null) ? false : true
    }, () => {
      if (this.state.selectedDays) {
        this.handleDayChange(this.state.selectedDays[0], {})
      }
      else {
        this.handleDayChange(new Date(), {})
      }
    });
  };

  handleCountryChangeFilter = (e) => {
    this.resetDivision();
    this.resetClient();
    let temp = e.value
    this.setState({
      Filter_CountrySelectedOption: e,
    }, () => {
      fetchDivisionOnCountry(temp).then((data) => {
        this.setState({
          divisions: data.result,
          disableDivisionFilter: (temp != "") ? false : true
        })
      }).catch(err => console.log(err))
    });
  };

  handleDivisonChangeFilter = (e) => {
    let temp = e.value
    this.setState({
      Filter_DivisonSelectedOption: e,
    }, () => {
      fetchAllClients(temp, this.state.Filter_CountrySelectedOption.value).then((data) => {
        this.setState({
          clients: data[0].data.result,
          disableClientFilter: (temp != "") ? false : true
        })
      }).catch(err => console.log(err))
    });
  };

  handleClientChangeFilter = (e) => {
    this.setState({
      Filter_selectedOption: e,
    });
  };

  handleTextChange(event) {
    let fieldVal = event.target.value;
    let tempState = this.state.userSelectedDays
    for (let key in tempState) {
      tempState[key].comments = fieldVal
    }
    this.setState({
      "textArea": fieldVal,
      userSelectedDays: tempState
    });
  }

  handleIncludeExcludeWeekend = (event) => {
    let fieldVal = event.target.value;
    this.setState({
      weekendsStatus: fieldVal
    });
  }

  handleReplicateHour = (event) => {
    let fieldVal = event.target.value;
    this.setState({
      hoursForAll: fieldVal
    });
  }

  replicateHoursToAllDates = () => {
    let tempState = this.state.userSelectedDays
    tempState.map((value, index) => {
      if (this.state.weekendsStatus == 0) {
        if (moment(tempState[index].work_date).format('dddd') != "Sunday" &&
          moment(tempState[index].work_date).format('dddd') != "Saturday" && tempState[index].status != 1) {
          tempState[index].work_time = this.state.hoursForAll
          tempState[index].comments = this.state.textArea
          tempState[index].log_type = 'monthly'
        }
        else {
          if (tempState[index].status != 1)
          tempState[index].work_time = '00:00'
          tempState[index].comments = this.state.textArea
          tempState[index].log_type = 'monthly'
        }
      } else if (this.state.weekendsStatus == 1 && tempState[index].status != 1) {
        tempState[index].work_time = this.state.hoursForAll
        tempState[index].comments = this.state.textArea
        tempState[index].log_type = 'monthly'
      }
    })
    this.setState({
      userSelectedDays: tempState
    })
  }

  handleHourChange = (event, index) => {
    let tempState = this.state.userSelectedDays
    tempState[index].work_time = event.target.value
    tempState[index].comments = this.state.textArea
    tempState[index].log_type = 'monthly'
    this.setState({
      userSelectedDays: tempState
    })
  }

  insertWeeklyWorkLog = () => {
    if (this.state.CountrySelectedOption == null) {
      toast.error("Please select country.");
    }
    else if (this.state.DivisonSelectedOption == null) {
      toast.error("Please select division.");
    }
    else if (this.state.selectedOption == null) {
      toast.error("Please select client.");
    }
    else if (this.state.selectedDays == null) {
      toast.error("Please select date.");
    }
    else {
      this.setState({
        disableSaveButton: true
      }, () => {
        let url = BASE_URL + "/insertweeklyworklog"
      axios.post(url, {
        data: this.state.userSelectedDays,
        log_type: 'monthly',
        'timeZone': timeZone,
        token: userData.authToken
      })
        .then((response) => {
          this.resetFields();
          toast.success("Hours Logged.");
          this.getLatestData()
        })

      })
      
    }
  }

  editWorkLog = (data) => {
    data.timeZone = timeZone
    fetchDataById(data).then((res) => {
      let totalHoursInMonth = (businessDays(moment(res.results[0].work_date).toDate(), 'DD-MM-YYYY').monthBusinessDays()).length
      totalHoursInMonth = totalHoursInMonth * 8
      totalHoursInMonth = totalHoursInMonth + ":00"

      calculateRequiredWorkType(userData["employee_id"], timeZone, getWeekRange(res.results[0].work_date), totalHoursInMonth).then((data) => {
        
        let temp_str = this.getLoggedText(data)
        this.setState({
          totalHoursAvailed: temp_str
        })
      })
      
      let temp_year = moment(getWeekRange(res.results[0].work_date).from).year()
      let temp_month = moment(getWeekRange(res.results[0].work_date).from).month()

      fetchDivisionOnCountry(res.results[0].country_id).then((division_data) => {
        fetchAllClients(res.results[0].division_id, res.results[0].country_id).then((client_data) => {
          let countryFound = this.state.countries.find((elem) => {
            return elem.value === res.results[0].country_id
          })

          let divisionFound = division_data.result.find((elem) => {
            return elem.value === res.results[0].division_id
          })

          let clientFound = client_data[0].data.result.find((elem) => {
            return elem.value === res.results[0].client_id
          })

          this.setState({
            clients: client_data[0].data.result,
            divisions: division_data.result,
            disableDivision: (res.results[0].country_id != "") ? false : true,
            disableClient: (res.results[0].division_id != "") ? false : true,
            CountrySelectedOption: countryFound,
            DivisonSelectedOption: divisionFound,
            selectedOption: clientFound,
            initialDate: new Date(temp_year, temp_month),
            disableDays: false,
            userSelectedDays: res.results,
            selectedDays: getWeekDays(getWeekRange(res.results[0].work_date).from),
            textArea: res.results[0].comments,
          })
        }).catch(err => console.log(err))
      }).catch(err => console.log(err))
      
    })
  }

  duplicateWorkLog = (data) => {
    data.LogEntryType = "monthly"
    data.timeZone = timeZone
    getDataForDuplicate(data).then((res) => {
      let results = res.results
      let temp_year = moment(getWeekRange(results[0].work_date).from).year()
      let temp_month = moment(getWeekRange(results[0].work_date).from).month()

      fetchDivisionOnCountry(res.results[0].country_id).then((division_data) => {
        fetchAllClients(res.results[0].division_id, res.results[0].country_id).then((client_data) => {
          let countryFound = this.state.countries.find((elem) => {
            return elem.value === res.results[0].country_id
          })

          let divisionFound = division_data.result.find((elem) => {
            return elem.value === results[0].division_id
          })

          let clientFound = client_data[0].data.result.find((elem) => {
            return elem.value === results[0].client_id
          })
          this.setState({
            divisions: division_data.result,
            clients: client_data[0].data.result,
            disableDivision: (res.results[0].country_id != "") ? false : true,
            disableClient: (results[0].division_id != "") ? false : true,
            CountrySelectedOption: countryFound,
            DivisonSelectedOption: divisionFound,
            selectedOption: clientFound,
            initialDate: new Date(temp_year, temp_month),
            disableDays: false,
            userSelectedDays: results,
            selectedDays: getWeekDays(getWeekRange(results[0].work_date).from),
            textArea: results[0].comments,
            totalHoursAvailed: ''
          })
          
        }).catch(err => console.log(err))
      }).catch(err => console.log(err))
      
    })

  }

  deleteWorkLog = (data) => {
    data.LogEntryType = "monthly"
    data.timeZone = timeZone
    this.dialog.show({
      title: 'Confirmation',
      body: 'Are you sure you want to delete this entry?',
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(() => {
          let url = BASE_URL + "/deleteweeklyworklog"

          axios.post(url, {
            data: data,
            token: userData.authToken
          })
            .then((response) => {
              this.getLatestData()
              this.resetFields();
            })
            .catch(function (error) {
              console.log(error);
            });
        })
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  render() {
    const { selectedOption, hoverRange, 
      selectedDays, DivisonSelectedOption, 
      CountrySelectedOption, from, to,
      Filter_selectedOption, Filter_DivisonSelectedOption, 
      Filter_CountrySelectedOption } = this.state;
    const modifiers_filter = { start: from, end: to };
    const modifiers = {
      hoverRange,
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
    };

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12} className="custom-table-holder">
              <Card
                title="Monthly Entry"
                ctTableResponsive
                content={
                  <div className="table-min-width">
                    <ToastContainer />
                    <Dialog ref={(component) => { this.dialog = component }} />
                    <Table className="custom-table" striped hover>
                      <thead>
                        <tr>
                          <th colSpan='1' style={{ width: "200px" }}>Division/Client</th>
                          <th colSpan='1' style={{ width: "100px", textAlign: "center" }}>Date</th>
                          <th colSpan='3' style={{ width: "250px", textAlign: "center" }}>Comments</th>
                          <th colSpan='2' style={{ width: "133px", textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan='1'>
                            <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                              <Select
                                value={CountrySelectedOption}
                                onChange={(e) => this.handleCountryChange(e)}
                                options={this.state.countries}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body}
                              />
                            </FormGroup>
                            <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                              <Select
                                value={DivisonSelectedOption}
                                onChange={(e) => this.handleDivisonChange(e)}
                                options={this.state.divisions}
                                isDisabled={this.state.disableDivision}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body}
                              />
                            </FormGroup>
                            <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                              <Select
                                value={selectedOption}
                                onChange={(e) => this.handleClientChange(e)}
                                options={this.state.clients}
                                isDisabled={this.state.disableClient}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body} 
                              />
                            </FormGroup>
                          </td>
                          <td colSpan='1'>
                            <div style={(this.state.totalHoursAvailed !== undefined && this.state.totalHoursAvailed != "") ? { display: 'block' } : { display: 'none'}}><label className="hours-msg">{this.state.totalHoursAvailed}</label></div>
                            <DayPicker
                              month={this.state.initialDate}
                              selectedDays={selectedDays}
                              showOutsideDays
                              modifiers={modifiers}
                              onDayClick={this.handleDayChange}
                              disabledDays={day => (this.state.disableDays) ? day : false}
                            />
                          </td>
                          <td colSpan='3'>
                            <FormGroup controlId="formControlsTextarea">
                              <FormControl
                                rows="5"
                                componentClass="textarea"
                                bsClass="form-control"
                                placeholder="Here can be your description"
                                value={this.state.textArea}
                                data-gramm="false"
                                data-gramm_editor="false"
                                data-enable-grammarly="false"
                                onChange={this.handleTextChange.bind(this)}
                              />
                            </FormGroup>
                          </td>
                          <td colSpan='2' className="btn-holder table-actionbtn">
                            <Button className="btn-success" onClick={this.insertWeeklyWorkLog} disabled={this.state.disableSaveButton} data-toggle="tooltip" title="Save" fill>
                              <i className="fa fa-floppy-o" aria-hidden="true"></i>
                              <span>Save</span>
                            </Button>
                            <Button className="btn-clear" onClick={this.resetFields} data-toggle="tooltip" title="Clear" pullRight fill>
                              <i className="fa fa-eraser" aria-hidden="true"></i>
                              <span>Clear</span>
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    {this.state.userSelectedDays.length > 0 &&
                      (
                        <div>
                          {/* <div className="table-holder">
                            <div className="table-block-weekend">

                            </div>
                          </div> */}
                          <div className="row" style={{ marginLeft: "0px" }}>
                            <div className="col-md-5">
                              <FormGroup controlId="formControlsSelect" className="WeekendSelectSeprate mb-0 mt-10">
                                <ControlLabel>Do you want to replicate same number of hours to each day?</ControlLabel>
                              </FormGroup>
                            </div>
                            <div className="col-md-3">
                              <FormControl componentClass="select" value={this.state.weekendsStatus} placeholder="select" onChange={(e) => this.handleIncludeExcludeWeekend(e)}>
                                <option value=''>Please Select</option>
                                <option value='0'>Exclude Weekends</option>
                                <option value='1'>Include Weekends</option>
                              </FormControl>
                            </div>
                            <div className="col-md-3">
                              <FormControl componentClass="select" value={this.state.hoursForAll} placeholder="select" onChange={(e) => this.handleReplicateHour(e)}>
                                <option value=''>Please Select</option>
                                {timeOptions.map((prop, key) => {
                                  return (<option key={key} value={prop.value}>{prop.label}</option>)
                                })}
                              </FormControl>
                            </div>
                            <div className="col-md-1">
                              <Button className="btn-fill pull-right btn btn-warning default-copybtn" bsStyle="warning" onClick={this.replicateHoursToAllDates} data-toggle="tooltip" title="Copy" >
                                Copy
                              </Button>
                            </div>
                          </div>
                          <hr></hr>
                        </div>
                      )
                    }
                    <div className="table-holder">
                      {this.state.userSelectedDays.map((prop, key) => {
                        return (
                          <div key={key} className="table-block" >
                            <FormGroup controlId="formControlsSelect" className="DaySelectSeprate w-100" 
                            style={(prop.status !== undefined && (prop.status == 2 || prop.status == 3)) ? 
                              { backgroundColor: '#F9EBEA' } : (prop.is_holiday !== undefined && prop.is_holiday !== 0) ? 
                              { backgroundColor: '#fff3cd' } : {}}>
                              <ControlLabel>{moment(prop.work_date).format('MMM DD,YYYY')}</ControlLabel>
                              <FormControl componentClass="select" style={(prop.alreadyExist !== undefined && prop.alreadyExist == 1 && prop.work_time !== "00:00") ? { border: '2px solid #B00020' } : {}} value={prop.work_time} disabled={prop.status == 1 ? true : ''} placeholder="select" onChange={(e) => this.handleHourChange(e, key)}>
                                {timeOptions.map((prop, key) => {
                                  return (<option key={key} value={prop.value}>{prop.label}</option>)
                                })}
                              </FormControl>
                              {(prop.alreadyExist !== undefined && prop.alreadyExist == 1 && prop.work_time !== "00:00") ? <ControlLabel style={{ color: '#B00020' }}>* Entry Already Exists</ControlLabel> : <ControlLabel></ControlLabel>}
                            </FormGroup>
                          </div>
                        )
                      })}

                    </div>
                  </div>
                }
              />
            </Col>
          </Row>

        </Grid>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Monthly Hours Logged"
                category=""
                ctTableResponsive
                content={
                  <div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="col-md-2">
                          <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                            <Select
                              value={Filter_CountrySelectedOption}
                              onChange={(e) => this.handleCountryChangeFilter(e)}
                              options={this.state.countries}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                              placeholder="Select Country"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-2">
                          <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                            <Select
                              value={Filter_DivisonSelectedOption}
                              onChange={(e) => this.handleDivisonChangeFilter(e)}
                              options={this.state.divisions}
                              isDisabled={this.state.disableDivisionFilter}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                              placeholder="Select Division"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-2">
                          <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                            <Select
                              value={Filter_selectedOption}
                              onChange={(e) => this.handleClientChangeFilter(e)}
                              options={this.state.clients}
                              isDisabled={this.state.disableClientFilter}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                              placeholder="Select Client"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-4">
                          <div className="InputFromTo">
                            <DayPickerInput
                              value={from}
                              placeholder="From"
                              format="LL"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                selectedDays: [from, { from, to }],
                                disabledDays: { after: to },
                                toMonth: to,
                                modifiers_filter,
                                numberOfMonths: 2,
                                onDayClick: () => this.to.getInput().focus(),
                              }}
                              onDayChange={this.handleFromChange}
                            />{' '}
                            —{' '}
                            <span className="InputFromTo-to">
                              <DayPickerInput
                                ref={el => (this.to = el)}
                                value={to}
                                placeholder="To"
                                format="LL"
                                formatDate={formatDate}
                                parseDate={parseDate}
                                dayPickerProps={{
                                  selectedDays: [from, { from, to }],
                                  disabledDays: { before: from },
                                  modifiers,
                                  month: from,
                                  fromMonth: from,
                                  numberOfMonths: 2,
                                }}
                                onDayChange={this.handleToChange}
                              />
                            </span>
                            <Helmet>
                              <style>{`
                              .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                                background-color: #f0f8ff !important;
                                color: #4a90e2;
                              }
                              .InputFromTo .DayPicker-Day {
                                border-radius: 0 !important;
                              }
                              .InputFromTo .DayPickerInput input{
                                max-width: 140px !important;
                              }
                              .InputFromTo-to .DayPickerInput input{
                                max-width: 140px !important;
                              }
                              .InputFromTo .DayPicker-Day--start {
                                border-top-left-radius: 50% !important;
                                border-bottom-left-radius: 50% !important;
                              }
                              .InputFromTo .DayPicker-Day--end {
                                border-top-right-radius: 50% !important;
                                border-bottom-right-radius: 50% !important;
                              }
                              .InputFromTo .DayPickerInput-Overlay {
                                width: 350px;
                              }
                              .InputFromTo-to .DayPickerInput-Overlay {
                                margin-left: -198px;
                              }
                            `}</style>
                            </Helmet>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <Button className="btn-edit" fill data-toggle="tooltip" title="Search" onClick={() => this.prepareQueryObj()}>
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </Button>
                          <Button className="btn-clear ml-2" fill data-toggle="tooltip" title="Reset" onClick={() => this.clearFilters()}>
                            <i className="fa fa-eraser" aria-hidden="true"></i>
                          </Button>
                        </div>

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <DataTable
                          title=""
                          className="daily-hours-table"
                          columns={columns}
                          noHeader
                          data={this.state.workLogs}
                          expandableRows
                          expandableRowsComponent={<CommentComponent />}
                          defaultSortField="start_date"
                          pagination
                          conditionalRowStyles={conditionalRowStyles}
                          paginationRowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                        />
                      </div>
                    </div>
                  </div>
                  
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default MonthlyLog;
