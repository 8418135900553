/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Select from 'react-select';
import ReactDOM from 'react-dom';
import { Grid, Row, Col, Table } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import {
  fetchEmployeesUnderManager,
  fetchAllLogsDetail
} from "../helper/helper.jsx"
import DataTable from 'react-data-table-component';
import Button from "components/CustomButton/CustomButton.jsx";

import Cookies from 'js-cookie';

import 'react-tabs/style/react-tabs.css';


import 'react-day-picker/lib/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from 'react-bootstrap-dialog'

import {
  FormGroup,
  FormControl,
} from "react-bootstrap";

const axios = require('axios');
let userData = {}
if (Cookies.get('userData')) {
  userData = JSON.parse(Cookies.get('userData'))
}

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

class ManagerView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee_selectedOption: '',
      employees: [],
      logsDetail: [],
    };
  }

  calendarRef = React.createRef()

  createLegend() {
    var legend = [];
    var json = {
      names: ["Exact 8 hours", "Above 8 hours", "Below 8 hours"],
      types: ["exact", "above", "below"]
    };

    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i] + " ");
    }
    return legend;
  }

  handleEmployeeChange = (e) => {
    let temp = e.value
    this.setState({
      employee_selectedOption: e,
    });
  };

  getSelectedUserLogs = () => {
    fetchAllLogsDetail(this.state.employee_selectedOption.value, new Date(), timeZone).then((data) => {
      let res = data.results
      res.map((value, index) => {
        if (value.timeObj.hours == 8 && value.timeObj.minutes == '00') {
          res[index].color = '#00B0A3'
        } else if (value.timeObj.hours < 8) {
          res[index].color = '#D93D7A'
        } else if (value.timeObj.hours > 8 || (value.timeObj.hours >= 8 && value.timeObj.minutes !== "00" )) {
          res[index].color = '#16ABE0'
        }
      })
      this.setState({
        logsDetail: res
      })
    }).catch(err => console.log(err))
  }


  componentDidMount() {
    fetchEmployeesUnderManager(userData["employee_id"]).then((data) => {
      this.setState({
        employees: data.results
      })
    })
  }

  resetFields = () => {
    this.setState({
      employee_selectedOption: '',
      logsDetail: [],
    });
  }

  fetchNext (e) {
    let calendarApi = this.calendarRef.current.getApi()
    calendarApi.next()
    var elems = document.getElementsByClassName("fc-next-button");
    for(var i = 0; i < elems.length; i++) {
        elems[i].disabled = true;
    }
    let next_date = calendarApi.getDate()
    fetchAllLogsDetail(this.state.employee_selectedOption.value, next_date, timeZone).then((data) => {
      let res = data.results
      res.map((value, index) => {
        if (value.timeObj.hours == 8 && value.timeObj.minutes == '00') {
          res[index].color = '#00B0A3'
        } else if (value.timeObj.hours < 8) {
          res[index].color = '#D93D7A'
        } else if (value.timeObj.hours > 8 || (value.timeObj.hours >= 8 && value.timeObj.minutes !== "00" )) {
          res[index].color = '#16ABE0'
        }
      })

      this.setState({
        logsDetail: res,
      })
      var elems = document.getElementsByClassName("fc-next-button");
      for(var i = 0; i < elems.length; i++) {
          elems[i].disabled = false;
      }
    }).catch(err => console.log(err))
  }

  fetchPrev (e) {
    let calendarApi = this.calendarRef.current.getApi()
    calendarApi.prev()
    var elems = document.getElementsByClassName("fc-prev-button");
    for(var i = 0; i < elems.length; i++) {
        elems[i].disabled = true;
    }
    let prev_date = calendarApi.getDate()
    fetchAllLogsDetail(this.state.employee_selectedOption.value, prev_date, timeZone).then((data) => {
      let res = data.results
      res.map((value, index) => {
        if (value.timeObj.hours == 8 && value.timeObj.minutes == '00') {
          res[index].color = '#00B0A3'
        } else if (value.timeObj.hours < 8) {
          res[index].color = '#D93D7A'
        } else if (value.timeObj.hours > 8 || (value.timeObj.hours >= 8 && value.timeObj.minutes !== "00" )) {
          res[index].color = '#16ABE0'
        }
      })

      this.setState({
        logsDetail: res,
      })
      var elems = document.getElementsByClassName("fc-prev-button");
      for(var i = 0; i < elems.length; i++) {
          elems[i].disabled = false;
      }
    }).catch(err => console.log(err))
  }

  fetchToday (e) {
    let calendarApi = this.calendarRef.current.getApi()
    calendarApi.today()
    fetchAllLogsDetail(this.state.employee_selectedOption.value, new Date(), timeZone).then((data) => {
      let res = data.results
      res.map((value, index) => {
        if (value.timeObj.hours == 8 && value.timeObj.minutes == '00') {
          res[index].color = '#00B0A3'
        } else if (value.timeObj.hours < 8) {
          res[index].color = '#D93D7A'
        } else if (value.timeObj.hours > 8 || (value.timeObj.hours >= 8 && value.timeObj.minutes !== "00" )) {
          res[index].color = '#16ABE0'
        }
      })

      this.setState({
        logsDetail: res,
      })
    }).catch(err => console.log(err))
  }

  render() {
    const { employee_selectedOption, logsDetail } = this.state;

    const EventDetail = ({ event, el }) => {
      const content = <div>{event.title}<div>{event.description}</div></div>;
      ReactDOM.render(content, el);
      return el;
    };

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Employee Calendar"
                category=""
                ctTableResponsive
                content={
                  <div>
                    <ToastContainer />
                    <Dialog ref={(component) => { this.dialog = component }} />
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="col-md-3">
                          <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                            <label className="form-label">Employee</label>
                            <Select
                              value={employee_selectedOption}
                              onChange={(e) => this.handleEmployeeChange(e)}
                              options={this.state.employees}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-2">
                          <Button className="btn-edit mt-24" fill data-toggle="tooltip" title="Search" onClick={() => this.getSelectedUserLogs()}>
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </Button>
                          <Button className="btn-clear ml-2 mt-24" fill data-toggle="tooltip" title="Reset" onClick={() => this.resetFields()}>
                            <i className="fa fa-eraser" aria-hidden="true"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="row">
                      <div className="col-md-12">
                        <Card
                          title=""
                          category={
                            <span className="legend">{this.createLegend()}</span>
                          }
                          ctTableResponsive
                          content={
                            <FullCalendar
                              defaultView="dayGridMonth"
                              plugins={[dayGridPlugin]}
                              events={logsDetail}
                              eventRender={EventDetail}
                              customButtons={{
                                next: {
                                    text: 'Next',
                                    click: (e) => this.fetchNext(e)
                                },
                                prev: {
                                  text: 'Prev',
                                  click: (e) => this.fetchPrev(e)
                                },
                                today: {
                                  text: 'Today',
                                  click: (e) => this.fetchToday(e)
                                }
                              }}
                              header={{
                                right: 'prev,next, today',
                              }}
                              ref={this.calendarRef}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ManagerView;
