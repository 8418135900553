import React, { Component } from "react";
import Select from 'react-select';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';

import Autosuggest from 'react-autosuggest';
import {RadioGroup, Radio} from 'react-radio-group';

import {
  fetchAllCountry,
  fetchDivisionList,
  BASE_URL,
  checkDivisionAvailability
} from "../helper/helper.jsx"

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Dialog from 'react-bootstrap-dialog'

import Cookies from 'js-cookie';
import { DIVISION_TABLE_HEAD } from '../helper/data'
const axios = require('axios');

let division_columns = DIVISION_TABLE_HEAD
let userData = {}
if (Cookies.get('userData')) {
  userData = JSON.parse(Cookies.get('userData'))
}

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

class Division extends Component {
  constructor(props) {
    super(props);
    this.state = {
      divisions: [],
      suggested_divisions: [],
      filter_division: [],
      all_division: [],
      division: '',
      division_already_exist: false,
      CountrySelectedOption: null,
      countries: [],
      DivisonSelectedOption: null,
      disableDivision: true,
      radioSelectedValue: '1',
      oldRecord: {},
    };
  }

  componentDidMount() {
    this.fetchAllCountryList()
    this.fetchDivisionList()
  }

  handleRadioChange = (value) => {
    if (value == 1) {
      this.setState({ division: ''})
    } else {
      this.setState({ DivisonSelectedOption: null})
    }
    this.setState({
      radioSelectedValue: value
    });
  }

  handleCountryChange = (e) => {
    let temp = e.value
    this.setState({
      CountrySelectedOption: e,
      disableDivision: (temp != "") ? false : true
    }, () => {
      if (this.state.DivisonSelectedOption != null) {
        checkDivisionAvailability({division_id : this.state.DivisonSelectedOption.value, country_id : this.state.CountrySelectedOption.value}).then((data) => {
          let isExist = data.results[0].total_rows
          if (isExist) {
            this.setState({ division_already_exist : true })
          }
          else {
            this.setState({ division_already_exist : false })
          }
        }).catch(err => console.log(err))
      }
      
      fetchDivisionList().then((data) => {
        this.setState({
          divisions: data[0].data.results.division,
        })
      }).catch(err => console.log(err))
    });
  };

  handleDivisonChange = (e) => {
    let temp = e.value
    this.setState({
      DivisonSelectedOption: e,
    }, () => {
      checkDivisionAvailability({division_id : temp, country_id : this.state.CountrySelectedOption.value}).then((data) => {
        let isExist = data.results[0].total_rows
        if (isExist) {
          this.setState({ division_already_exist : true })
        }
        else {
          this.setState({ division_already_exist : false })
        }
      }).catch(err => console.log(err))
    });
  };
  renderButtonHTML = (data) => {
    return (
      <div className="table-btn-holder">
        <Button className="btn-edit" fill data-toggle="tooltip" title="Edit" onClick={() => this.editDivision(data)}>
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </Button>
        <Button className="btn-delete ml-2" fill data-toggle="tooltip" title="Delete" onClick={() => this.deleteDivisionLink(data)}>
          <i className="fa fa-trash" aria-hidden="true"></i>
        </Button>
      </div>
    )
  }

  fetchDivisionList () {
    fetchDivisionList().then((data) => {
      let all_division_list = data[0].data.results.division_record
      all_division_list.map((value, index) => {
        if (value.total_record == 0) {
          all_division_list[index].action = this.renderButtonHTML(value)
        }
      })
      this.setState({
        filter_division: data[0].data.results.division,
        all_division: all_division_list
      })
    }).catch(err => console.log(err))
  }

  fetchAllCountryList () {
    fetchAllCountry().then((data) => {
      this.setState({
        countries: data[0].data.result
      })
    }).catch(err => console.log(err))
  }


  submitRequest = () => {
    if (this.state.radioSelectedValue == 1) {
      if (this.state.CountrySelectedOption == null) {
        toast.error("Please select country.");
      }
      else if (this.state.DivisonSelectedOption == null) {
        toast.error("Please select division.");
      }
      else {
        let url = BASE_URL + "/createNewDivisionLink"
        
        let req_data = {
          "division": this.state.DivisonSelectedOption.value,
          "country": this.state.CountrySelectedOption.value,
          "oldRecord": this.state.oldRecord
        }
        axios.post(url, {
          data: req_data,
          token: userData.authToken
        }).then((response) => {
          this.resetFields();
          toast.success("Division Link Created!");
          this.fetchDivisionList()
        })

      }
    }
    else {
      if (this.state.CountrySelectedOption == null) {
        toast.error("Please select country.");
      }
      else if (this.state.division == "") {
        toast.error("Please provide division.");
      }
      else {
        let url = BASE_URL + "/insertDivision"
        
        let req_data = {
          "division": this.state.division,
          "country": this.state.CountrySelectedOption.value,
          "oldRecord": this.state.oldRecord
        }
        axios.post(url, {
          data: req_data,
          token: userData.authToken
        }).then((response) => {
          this.resetFields();
          toast.success("Division Created!");
          this.fetchDivisionList()
        })
  
      }
    }
    
  }

  editDivision = (data) => {
    fetchDivisionList().then((response) => {
      let divisionFound = this.state.filter_division.find((elem) => {
        return elem.value === data.division_id
      })
      let countryFound = this.state.countries.find((elem) => {
        return elem.value === data.country_id
      })
      this.setState({
        oldRecord: data, 
        divisions: response[0].data.results.division,
        CountrySelectedOption: countryFound,
        DivisonSelectedOption: divisionFound,
        disableDivision: (data.country_id != "") ? false : true,
        disableClient: (data.division_id != "") ? false : true,
        division_already_exist: true,
        division: divisionFound.label
      })
    }).catch(err => console.log(err))

  }

  deleteDivisionLink = (data) => {
    this.dialog.show({
      title: 'Confirmation',
      body: 'Are you sure you want to delete this division-country link?',
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(() => {
          let url = BASE_URL + "/deleteDivisionLink"
          data.employee_id = userData["employee_id"]
          axios.post(url, {
            data: data,
            token: userData.authToken
          })
            .then((response) => {
              this.fetchDivisionList()
              this.resetFields();
            })
            .catch(function (error) {
              console.log(error);
            });
        })
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  handleDivision = (e) => {
    this.setState({division: e.target.value})
  }

  resetFields = () => {
    this.setState({
      division: '',
      division_already_exist: false,
      CountrySelectedOption: null,
      DivisonSelectedOption: null,
      disableDivision: true,
      radioSelectedValue: '1',
      oldRecord: {}
    });
  }

  getSuggestions = value => {
    let filter_division = this.state.filter_division
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
   
    let foundCountry = inputLength === 0 ? [] : filter_division.filter(division =>
      division.label.toLowerCase().slice(0, inputLength) === inputValue
    );
    this.setState({
      division_already_exist: foundCountry.length > 0 ? true : false
    })

    return foundCountry
  };


  getSuggestionValue = suggestion => suggestion.label;

  renderSuggestion = suggestion => suggestion.label;

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggested_divisions: this.getSuggestions(value)
    });
  };
 
  onSuggestionsClearRequested = () => {
    this.setState({
      suggested_divisions: []
    });
  };
  suggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.setState({division: suggestionValue})
  }

  render() {
    const { value, suggested_divisions, CountrySelectedOption, DivisonSelectedOption } = this.state;

    const inputProps = {
      placeholder: 'division name',
      value: this.state.division,
      onChange: this.handleDivision,
      className:"form-control",
      disabled:this.state.disableDivision
    };

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col className= "col-md-offset-2 col-md-8">
              <Card
                title=""
                category=""
                content={
                  <div>
                    <ToastContainer />
                    <Dialog ref={(component) => { this.dialog = component }} />
                    <div className="row">
                      <div className="col-md-offset-3 col-md-6">
                        <label>Please Select Country</label>
                        <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                          <Select
                            value={CountrySelectedOption}
                            onChange={(e) => this.handleCountryChange(e)}
                            options={this.state.countries}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                          />
                        </FormGroup>
                        <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                          <RadioGroup
                            name="radio"
                            className = "radio-new-line"
                            selectedValue={this.state.radioSelectedValue}
                            onChange={this.handleRadioChange}>
                            <label>
                              <Radio value="1" /> Link existing division
                            </label>
                            <label>
                              <Radio value="2" /> Create new division and link it
                            </label>
                            
                          </RadioGroup>
                        </FormGroup>
                        <div style={(this.state.radioSelectedValue == 1) ? {display: 'block'} : {display: 'none'}}>
                          <label>Link existing division as new in country</label>
                          <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                            <Select
                              value={DivisonSelectedOption}
                              onChange={(e) => this.handleDivisonChange(e)}
                              options={this.state.divisions}
                              isDisabled={this.state.disableDivision}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                            />
                          </FormGroup>
                        </div>
                        <div style={(this.state.radioSelectedValue == 2) ? {display: 'block'} : {display: 'none'}}>
                          <label>Create and link new division</label>
                          <FormGroup controlId="formControlsTextarea">
                            <Autosuggest
                              suggestions={suggested_divisions}
                              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              onSuggestionSelected={this.suggestionSelected}
                              inputProps={inputProps}
                            />
                          </FormGroup>
                        </div>
                        
                        {(this.state.division_already_exist !== undefined && this.state.division_already_exist) ? <ControlLabel style={{ color: '#B00020' }}>* Division Already Exists</ControlLabel> : <ControlLabel></ControlLabel>}
                      </div>
                      
                    </div>
                    <div className="row">
                      <div className="col-md-offset-3 col-md-6">
                        <Button className="btn-success" onClick={this.submitRequest} disabled={this.state.division_already_exist} fill>
                          <i className="fa fa-floppy-o" data-toggle="tooltip" title="Save" aria-hidden="true"></i>
                          <span>Save</span>
                        </Button>
                        <Button className="btn-clear ml-2" onClick={this.resetFields} data-toggle="tooltip" title="Clear" pullRight fill>
                          <i className="fa fa-eraser" aria-hidden="true"></i>
                          <span>Clear</span>
                        </Button>
                      </div> 
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col  className= "col-md-offset-2 col-md-8">
              <Card
                title="Division List"
                ctTableResponsive
                content={
                  <div className="row">
                      <div className="col-md-12">
                      <DataTable
                        title=""
                        className="daily-hours-table"
                        columns={division_columns}
                        data={this.state.all_division}
                        paginationRowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                        pagination
                      />
                      </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Division;
