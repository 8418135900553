/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import history from './history';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/custom.css";


import AdminLayout from "layouts/Admin.jsx";
import Login from "views/Login.jsx";
import Cookies from 'js-cookie';


require('dotenv').config()

let userData = {}
let authToken = null
if (Cookies.get('userData')) {
  userData = Cookies.get('userData')
  authToken = Cookies.get('authToken')
}


ReactDOM.render(
  <BrowserRouter>
    <Switch history={history}>
      {authToken != undefined && authToken != null
        ? <Route path="/timesheet" render={props => <AdminLayout {...props} userData={userData} />} />
        : <Route path="/timesheet/login" render={props => <Login {...props} />} />}
      <Redirect from="/" to="/timesheet/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
