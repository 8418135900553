/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import DailyLog from "views/DailyLog.jsx";
import WeeklyLog from "views/WeeklyLog.jsx";
import MonthlyLog from "views/MonthlyLog.jsx";
import VerifyHours from "views/VerifyHours.jsx";
import Cookies from 'js-cookie';
import ManagerView from "views/ManagerView.jsx";
import ContactUs from "views/ContactUs.jsx";
import Country from "views/Country.jsx";
import Division from "views/Division.jsx";
import Client from "views/Client.jsx";
import Employee from "views/Employee.jsx";
// import ActiveUser from "views/ActiveUser.jsx";

let dashboardRoutes = [
    // {
    //     path: "/active",
    //     name: "Active Users",
    //     icon: "pe-7s-chat",
    //     component: ActiveUser,
    //     layout: "/timesheet"
    // }, 
    {
        path: "/employee",
        name: "Employee",
        icon: "pe-7s-users",
        component: Employee,
        layout: "/timesheet"
    }, {
        path: "/country",
        name: "Country",
        icon: "pe-7s-global",
        component: Country,
        layout: "/timesheet"
    }, {
        path: "/division",
        name: "Division",
        icon: "pe-7s-help2",
        component: Division,
        layout: "/timesheet"
    }, {
        path: "/client",
        name: "Client",
        icon: "pe-7s-ribbon",
        component: Client,
        layout: "/timesheet"
    }, {
        path: "/verifyhours",
        name: "Approve Hours",
        icon: "pe-7s-check",
        component: VerifyHours,
        layout: "/timesheet"
    },
    {
        path: "/managerview",
        name: "Employee Calendar",
        icon: "pe-7s-graph3",
        component: ManagerView,
        layout: "/timesheet"
    },
    {
        path: "/dashboard",
        name: "Calendar",
        icon: "pe-7s-date",
        component: Dashboard,
        layout: "/timesheet"
    },
    {
        path: "/daily",
        name: "Daily",
        icon: "pe-7s-note2",
        component: DailyLog,
        layout: "/timesheet"
    },
    {
        path: "/weekly",
        name: "Weekly",
        icon: "pe-7s-note2",
        component: WeeklyLog,
        layout: "/timesheet"
    },
    {
        path: "/monthly",
        name: "Monthly",
        icon: "pe-7s-note2",
        component: MonthlyLog,
        layout: "/timesheet"
    },
    {
        path: "/contact-us",
        name: "Contact Us",
        icon: "pe-7s-call",
        component: ContactUs,
        layout: "/timesheet"
    },
];

let userData = {}
if (Cookies.get('userData')) {
    userData = JSON.parse(Cookies.get('userData'))
}

if (Object.keys(userData).length > 0 && !userData.isManager) {
    dashboardRoutes = dashboardRoutes.filter(el => el.path !== "/verifyhours");
    dashboardRoutes = dashboardRoutes.filter(el => el.path !== "/managerview");
}
if (Object.keys(userData).length > 0 && !userData.is_admin) {
    dashboardRoutes = dashboardRoutes.filter(el => el.path !== "/country");
    dashboardRoutes = dashboardRoutes.filter(el => el.path !== "/division");
    dashboardRoutes = dashboardRoutes.filter(el => el.path !== "/client");
    // dashboardRoutes = dashboardRoutes.filter(el => el.path !== "/employee");
}
if (Object.keys(userData).length > 0 && (userData.practice_lead == "0" && !userData.is_admin)) {
    dashboardRoutes = dashboardRoutes.filter(el => el.path !== "/employee");
}

export default dashboardRoutes;