import React, { Component } from "react";
import MicrosoftLogin from "react-microsoft-login";
import { authorizeUser } from "../helper/helper.jsx"
import history from '../history';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie'

export const COOKIE_OPTIONS = {
    path: "/",
    expires: new Date(new Date().getTime() + 1000 * 60 * 60)
   };

const authHandler = (err, data, msal) => {
    if (data !== undefined) {
        // if (data.authResponseWithAccessToken == undefined) {
        //     toast.error(<div>{`Please refresh the page and login again.`} <br /> {`To prevent this message to appear again, enable 3rd party cookies.`} <br /> {`Go to chrome://settings/cookies or Settings -> Cookies -> select “Allow all cookies”`} </div>);
        // }
        // else {
            // Cookies.set('authToken', data.authResponseWithAccessToken.idToken.rawIdToken, COOKIE_OPTIONS);
            Cookies.set('authToken', data.idToken.rawIdToken, COOKIE_OPTIONS);

            // authorizeUser(data.authResponseWithAccessToken.idToken.preferredName).then((data) => {
            authorizeUser(data.idToken.preferredName).then((data) => {
                if (data && data.length > 0 && data[0].data && data[0].data.status == 'success') {
                    Cookies.set('userData', data[0].data.result, COOKIE_OPTIONS);

                    history.push('/timesheet/daily');
                    window.location.reload();
                } else {
                    console.log(data)
                    toast.error("Something went wrong, Please contact Support!");
                }

            }).catch(err => console.log(err))
        // }
    } else {
        toast.error("Invalid credentails provided.");
    }

};

class Login extends Component {
    render() {
        return (
            <div className="login-holder">
                <div className="login-block">
                    <div className="login-text">
                        <h1>Publicis Media MENA Timesheets</h1>
                    </div>
                    <ToastContainer />
                    <MicrosoftLogin tenantUrl="https://login.microsoftonline.com/d52c9ea1-7c21-47b1-82a3-33a74b1f74b8" prompt="select_account" clientId={'02f0c0f1-6162-4ed5-a502-c27785b9742d'} redirectUri={window.location.href} authCallback={authHandler} />
                </div>
            </div>
        )
    }

}

export default Login;