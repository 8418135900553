import React, { Component } from "react";
import Select from 'react-select';
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import {
  fetchAllCountry,
  fetchDivisionOnCountry,
  fetchAllClients,
  fetchDailyDataById,
  getDataForDuplicate,
  BASE_URL,
  GetEntries,
  calculateRequiredWorkType,
  fetchDataById
} from "../helper/helper.jsx"
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Helmet from 'react-helmet';
import { formatDate, parseDate } from 'react-day-picker/moment';
import DataTable from 'react-data-table-component';
import Button from "components/CustomButton/CustomButton.jsx";
import {
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from 'react-bootstrap-dialog'
import moment from 'moment';

import Cookies from 'js-cookie';
import { DAILY_TABLE_HEAD, TIME_OPTIONS } from '../helper/data'
const axios = require('axios');

let userData = {}
if (Cookies.get('userData')) {
  userData = JSON.parse(Cookies.get('userData'))
}
let columns = DAILY_TABLE_HEAD
let timeOptions = TIME_OPTIONS
let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

const CommentComponent = ({ data }) => (
  <div>
    <p style={{fontSize: '13px'}}>
      {data.comments == "" ? "No comment available" : data.comments}
    </p> 
    <p style={(data.rejected_comment != null) ? {display: 'block', fontSize: '13px'} : {display: 'none'}}>
      {data.rejected_comment == "" ? "No rejected comment available" : "Rejected Comment: " + data.rejected_comment}
    </p>
  </div>
);

const conditionalRowStyles = [
  {
    when: row => row.status == 2 || row.status == 3,
    style: {
      backgroundColor: '#f8d7da',
      color: '#721c24',
    },
  },
];


class DailyLog extends Component {
  constructor(props) {
    super(props);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      selectedDay: null,
      selectedOption: null,
      DivisonSelectedOption: null,
      CountrySelectedOption: null,
      Filter_selectedOption: null,
      Filter_DivisonSelectedOption: null,
      Filter_CountrySelectedOption: null,
      textArea: undefined,
      workLogs: [],
      clients: [],
      divisions: [],
      countries: [],
      disableDays: true,
      disableClient: true,
      disableDivision: true,
      totalHoursAvailed: '',
      initialDate: new Date(),
      userSelectedDays: [
        {
          id: null,
          employee_id: "ABCD0029",
          client_id: "1",
          division_id: "1",
          country_id: "1",
          work_date: "2020-01-01",
          work_time: "00:00",
          comments: "",
          status: 0,
          log_type: "daily"
        }
      ],
      disableSaveButton: false,
      loading: false,
      totalRows: 0,
      perPage: 10,
      queryData: {},
      from: undefined,
      to: undefined,
      disableClientFilter: true,
      disableDivisionFilter: true,
      
    };
  }

  

  resetFields = () => {
    this.setState({
      selectedDay: null,
      selectedOption: null,
      DivisonSelectedOption: null,
      CountrySelectedOption: null,
      Filter_selectedOption: null,
      Filter_DivisonSelectedOption: null,
      Filter_CountrySelectedOption: null,
      textArea: "",
      clients: [],
      disableDays: true,
      disableClient: true,
      disableDivision: true,
      totalHoursAvailed: '',
      initialDate: new Date(),
      userSelectedDays: [
        {
          id: null,
          employee_id: "ABCD0029",
          client_id: "1",
          division_id: "1",
          country_id: "1",
          work_date: "2020-01-01",
          work_time: "00:00",
          comments: "",
          status: 0,
          log_type: "daily"
        }
      ],
      disableSaveButton: false,
      queryData: {},
      from: undefined,
      to: undefined,
    });
  }
  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }

  getLoggedText = (data) => {
    let temp_str = ""
    if (data.type == 1) {
      temp_str = "Hours remaining for the selected day " + data.value
    }
    else {
      temp_str = "You have overtime hours within this day " + data.value
    }
    return temp_str
  }

  renderButtonHTML = (data) => {
    if (data.allDataApproved == 0) {
      return (
        <div className="table-btn-holder">
          <Button className="btn-edit" fill data-toggle="tooltip" title="Edit" onClick={() => this.editWorkLog(data)}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </Button>
          <Button className="btn-duplicate ml-2 mr-2" fill data-toggle="tooltip" title="Duplicate" onClick={() => this.duplicateWorkLog(data)}>
            <i className="fa fa-files-o" aria-hidden="true"></i>
          </Button>
          <Button className="btn-delete" fill data-toggle="tooltip" title="Delete" onClick={() => this.deleteWorkLog(data)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Button>
        </div>
      )
    }
    else {
      return (
      <div className="table-btn-holder">
        <Button className="btn-duplicate mr-2" fill data-toggle="tooltip" title="Duplicate" onClick={() => this.duplicateWorkLog(data)}>
          <i className="fa fa-files-o" aria-hidden="true"></i>
        </Button>
      </div>
      )
    }

  }

  clearFilters = () => {
    const { perPage } = this.state;
    this.setState({ 
      loading: true,
      queryData: {},
      from: undefined,
      to: undefined,
      Filter_selectedOption: null,
      Filter_DivisonSelectedOption: null,
      Filter_CountrySelectedOption: null, 
    });
    this.getLatestData(1, perPage)
  }

  componentDidMount() {
    const { perPage } = this.state;
    this.setState({ loading: true });
    this.getLatestData(1, perPage)
    fetchAllCountry().then((data) => {
      this.setState({
        countries: data[0].data.result
      })
    }).catch(err => console.log(err))
  }

  resetDivision = () => {
    this.setState({
      DivisonSelectedOption: null,
      disableDivision: true,
      divisions: [],
    })
  }

  resetClient = () => {
    this.setState({
      clients: [],
      disableClient: true,
      selectedOption: null
    })
  }
  
  handleCountryChange = (e) => {
    this.resetDivision();
    this.resetClient();
    let temp = e.value
    this.setState({
      CountrySelectedOption: e,
    }, () => {
      fetchDivisionOnCountry(temp).then((data) => {
        this.setState({
          divisions: data.result,
          disableDivision: (temp != "") ? false : true
        })
      }).catch(err => console.log(err))
    });
  };

  handleDivisonChange = (e) => {
    let temp = e.value
    this.setState({
      DivisonSelectedOption: e,
    }, () => {
      fetchAllClients(temp, this.state.CountrySelectedOption.value).then((data) => {
        this.setState({
          "clients": data[0].data.result,
          disableClient: (temp != "") ? false : true
        })
      }).catch(err => console.log(err))
    });
  };

  handleClientChange = (e) => {
    this.setState({
      selectedOption: e,
      disableDays: (e.value != null) ? false : true
    }, () => {
      if (this.state.selectedDay) {
        this.handleDayChange(this.state.selectedDay, {})
      }
      else {
        this.handleDayChange(new Date(), {})
      }
      
    });
  };

  handleCountryChangeFilter = (e) => {
    this.resetDivision();
    this.resetClient();
    let temp = e.value
    this.setState({
      Filter_CountrySelectedOption: e,
    }, () => {
      fetchDivisionOnCountry(temp).then((data) => {
        this.setState({
          divisions: data.result,
          disableDivisionFilter: (temp != "") ? false : true
        })
      }).catch(err => console.log(err))
    });
  };

  handleDivisonChangeFilter = (e) => {
    let temp = e.value
    this.setState({
      Filter_DivisonSelectedOption: e,
    }, () => {
      fetchAllClients(temp, this.state.Filter_CountrySelectedOption.value).then((data) => {
        this.setState({
          clients: data[0].data.result,
          disableClientFilter: (temp != "") ? false : true
        })
      }).catch(err => console.log(err))
    });
  };

  handleClientChangeFilter = (e) => {
    this.setState({
      Filter_selectedOption: e,
    });
  };

  handleDayChange = (date, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    let reqObj = {
      'weekDays': { from: date, to: moment(date).add(1, 'days') },
      'selected_division': this.state.DivisonSelectedOption.value,
      'selected_client': this.state.selectedOption.value,
      'selected_country': this.state.CountrySelectedOption.value,
      'employee_id': userData["employee_id"],
      'LogEntryType': 'daily',
      'timeZone': timeZone,
      'userWorkTime':this.state.userSelectedDays
    }
    GetEntries(reqObj).then((data) => {
      let temp_date_obj = {}
      temp_date_obj.from = moment(data.results[0].work_date).toDate()
      temp_date_obj.to = moment(data.results[0].work_date).toDate()

      calculateRequiredWorkType(userData["employee_id"], timeZone, temp_date_obj, "08:00").then((data) => {
        let temp_str = this.getLoggedText(data)
        this.setState({
          totalHoursAvailed: temp_str
        })
      })
      
      this.setState({
        userSelectedDays: data.results,
        textArea: data.results[0].comments,
      })
    }).catch(err => console.log(err))


    this.setState({
      selectedDay: date,
    });
  }
  handleDayClick(day, { selected }) {
    this.setState({
      selectedDay: selected ? undefined : day,
    });
  }

  handleHourChange = (event, index) => {
    let tempState = this.state.userSelectedDays
    tempState[index].work_time = event.target.value
    tempState[index].comments = this.state.textArea
    tempState[index].log_type = 'daily'
    this.setState({
      userSelectedDays: tempState
    })
  }

  handleTextChange(event) {
    let fieldVal = event.target.value;
    let tempState = this.state.userSelectedDays
    for (let key in tempState) {
      tempState[key].comments = fieldVal
    }
    this.setState({
      "textArea": fieldVal,
      userSelectedDays: tempState
    });
  }

  getLatestData(page, perPage, queryData = {}) {
    let data = {
      employee_id: userData["employee_id"],
      timeZone: timeZone,
      page: page,
      perPage: perPage,
      queryData: queryData
    }
    fetchDailyDataById(data).then((data) => {
      let result_data = data[0].data.results.daily_record
      result_data.map((value, index) => {
        result_data[index].action = this.renderButtonHTML(value)
        result_data[index].start_date = result_data[index].work_date
        result_data[index].end_date = result_data[index].work_date
      })
      this.setState({
        workLogs: result_data,
        totalRows: data[0].data.results.total,
        loading: false,
      })

    })
  }

  insertWeeklyWorkLog = () => {
    if (this.state.CountrySelectedOption == null) {
      toast.error("Please select country.");
    }
    else if (this.state.DivisonSelectedOption == null) {
      toast.error("Please select division.");
    }
    else if (this.state.selectedOption == null) {
      toast.error("Please select client.");
    }
    else if (this.state.selectedDay == null) {
      toast.error("Please select date.");
    }
    else {
      this.setState({
        disableSaveButton: true
      }, () => {
        let url = BASE_URL + "/insertweeklyworklog"
        axios.post(url, {
          data: this.state.userSelectedDays,
          log_type: 'daily',
          timeZone: timeZone,
          token: userData.authToken
        })
          .then((response) => {
            this.resetFields();
            toast.success("Hours Logged.");
            this.getLatestData(1, this.state.perPage)
          })
      });
    }

  }

  editWorkLog = (data) => {
    data.timeZone = timeZone
    fetchDataById(data).then((res) => {
      let temp_date_obj = {}
      temp_date_obj.from = moment(res.results[0].work_date).toDate()
      temp_date_obj.to = moment(res.results[0].work_date).toDate()

      calculateRequiredWorkType(userData["employee_id"], timeZone, temp_date_obj, "08:00").then((data) => {
        let temp_str = this.getLoggedText(data)
        this.setState({
          totalHoursAvailed: temp_str
        })
      })

      let temp_year = moment(res.results[0].work_date).year()
      let temp_month = moment(res.results[0].work_date).month()


      fetchDivisionOnCountry(res.results[0].country_id).then((division_data) => {
        fetchAllClients(res.results[0].division_id, res.results[0].country_id).then((client_data) => {

          let countryFound = this.state.countries.find((elem) => {
            return elem.value === res.results[0].country_id
          })

          let divisionFound = division_data.result.find((elem) => {
            return elem.value === res.results[0].division_id
          })

          let clientFound = client_data[0].data.result.find((elem) => {
            return elem.value === res.results[0].client_id
          })

          this.setState({
            initialDate: new Date(temp_year, temp_month),
            disableDays: false,
            divisions: division_data.result,
            clients: client_data[0].data.result,
            disableDivision: (res.results[0].country_id != "") ? false : true,
            disableClient: (res.results[0].division_id != "") ? false : true,
            userSelectedDays: res.results,
            CountrySelectedOption: countryFound,
            DivisonSelectedOption: divisionFound,
            selectedOption: clientFound,
            selectedDay: new Date(res.results[0].work_date),
            textArea: res.results[0].comments,
          })
        }).catch(err => console.log(err))
      }).catch(err => console.log(err))
      
    })
  }
  duplicateWorkLog = (data) => {
    data.LogEntryType = "daily"
    data.timeZone = timeZone
    getDataForDuplicate(data).then((res) => {
      let results = res.results

      fetchDivisionOnCountry(res.results[0].country_id).then((division_data) => {
        fetchAllClients(res.results[0].division_id, res.results[0].country_id).then((client_data) => {
          let countryFound = this.state.countries.find((elem) => {
            return elem.value === res.results[0].country_id
          })

          let divisionFound = division_data.result.find((elem) => {
            return elem.value === results[0].division_id
          })

          let clientFound = client_data[0].data.result.find((elem) => {
            return elem.value === results[0].client_id
          })

          this.setState({
            divisions: division_data.result,
            disableDays: false,
            clients: client_data[0].data.result,
            disableDivision: (res.results[0].country_id != "") ? false : true,
            disableClient: (results[0].division_id != "") ? false : true,
            userSelectedDays: results,
            CountrySelectedOption: countryFound,
            DivisonSelectedOption: divisionFound,
            selectedOption: clientFound,
            selectedDay: new Date(res.results[0].work_date),
            textArea: results[0].comments,
            totalHoursAvailed: ''
          })
        }).catch(err => console.log(err))
      }).catch(err => console.log(err))
    })
  }

  deleteWorkLog = (data) => {
    data.LogEntryType = "daily"
    data.timeZone = timeZone
    this.dialog.show({
      title: 'Confirmation',
      body: 'Are you sure you want to delete this entry?',
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(() => {
          let url = BASE_URL + "/deleteweeklyworklog"

          axios.post(url, {
            data: data,
            token: userData.authToken
          })
            .then((response) => {
              this.getLatestData(1, this.state.perPage)
              this.resetFields();
            })
            .catch(function (error) {
              console.log(error);
            });
        })
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  handlePageChange = async page => {
    const { perPage } = this.state;
    this.setState({ loading: true });
    if (Object.keys(this.state.queryData).length == 0) {
      this.getLatestData(page, perPage)
    }
    else {
      this.getLatestData(page, perPage, this.state.queryData)
    }
  }

  handlePerRowsChange = async (perPage, page) => {
    this.setState({ loading: true });
    if (Object.keys(this.state.queryData).length == 0) {
      this.getLatestData(page, perPage)
    }
    else {
      this.getLatestData(page, perPage, this.state.queryData)
    }
    this.setState({
      perPage,
    });
  }

  prepareQueryObj() {
    let temp = {}
    if (this.state.Filter_CountrySelectedOption !== null) {
      temp.country_id = this.state.Filter_CountrySelectedOption.value
    }
    if (this.state.Filter_DivisonSelectedOption !== null) {
      temp.division_id = this.state.Filter_DivisonSelectedOption.value
    }
    if (this.state.Filter_selectedOption !== null) {
      temp.client_id = this.state.Filter_selectedOption.value
    }
    if (this.state.from !== undefined) {
      temp.from = this.state.from
    }
    if (this.state.to !== undefined) {
      temp.to = this.state.to
    }

    this.setState({
      queryData: temp
    }, () => {
      const { perPage } = this.state;
      this.setState({ loading: true });
      this.getLatestData(1, perPage, this.state.queryData)
    });

  }



  render() {
    const { 
      from, to, selectedOption, CountrySelectedOption, 
      DivisonSelectedOption, loading, totalRows,
      Filter_selectedOption, Filter_DivisonSelectedOption, 
      Filter_CountrySelectedOption} = this.state;
    const modifiers = { start: from, end: to };

    
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12} className="custom-table-holder">
              <Card
                className="overflow"
                title="Daily Entry"
                // category={this.state.totalHoursAvailed}
                ctTableResponsive
                content={
                  <div className="table-min-width">
                    <ToastContainer />
                    <Dialog ref={(component) => { this.dialog = component }} />
                    <Table striped hover>
                      <thead>
                        <tr>
                          <th colSpan='2' style={{ width: "200px" }}>Division/Client</th>
                          <th colSpan='2' style={{ width: "100px", textAlign: "center" }}>Date</th>
                          <th colSpan='1' style={{ width: "100px", textAlign: "center" }}>Hours</th>
                          <th colSpan='2' style={{ width: "300px" }}>Comments</th>
                          <th colSpan='3' style={{ width: "200px", textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan='2'>
                            <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                              <Select
                                value={CountrySelectedOption}
                                onChange={(e) => this.handleCountryChange(e)}
                                options={this.state.countries}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body}
                              />
                            </FormGroup>
                            <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                              <Select
                                value={DivisonSelectedOption}
                                onChange={(e) => this.handleDivisonChange(e)}
                                options={this.state.divisions}
                                isDisabled={this.state.disableDivision}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body}
                              />
                            </FormGroup>
                            <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                              <Select
                                value={selectedOption}
                                onChange={(e) => this.handleClientChange(e)}
                                options={this.state.clients}
                                isDisabled={this.state.disableClient}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body} 
                              />
                            </FormGroup>
                          </td>
                          <td colSpan='2' className="text-center">
                            <div style={(this.state.totalHoursAvailed !== undefined && this.state.totalHoursAvailed != "") ? { display: 'block' } : { display: 'none'}}><label className="hours-msg">{this.state.totalHoursAvailed}</label></div>
                            <DayPicker
                              month={this.state.initialDate}
                              selectedDays={this.state.selectedDay}
                              onDayClick={this.handleDayChange}
                              disabledDays={day => (this.state.disableDays) ? day : false}
                            />
                          </td>
                          {this.state.userSelectedDays.map((prop, key) => {
                            return (
                              <td colSpan='1' key={key}>
                                <FormGroup controlId="formControlsSelect" className="DaySelectSeprate">
                                  <FormControl className="mx-auto" componentClass="select" style={(prop.alreadyExist !== undefined && prop.alreadyExist == 1 && prop.work_time !== "00:00") ? { border: '2px solid #B00020' } : {}} value={prop.work_time} disabled={prop.status == 1 ? true : ''} placeholder="select" onChange={(e) => this.handleHourChange(e, key)}>
                                    {timeOptions.map((prop, key) => {
                                      return (<option key={key} value={prop.value}>{prop.label}</option>)
                                    })}
                                  </FormControl>
                                  {(prop.alreadyExist !== undefined && prop.alreadyExist == 1 && prop.work_time !== "00:00") ? <ControlLabel style={{ color: '#B00020' }}>* Entry Already Exists</ControlLabel> : <ControlLabel></ControlLabel>}
                                </FormGroup>

                              </td>
                            )
                          })}
                          <td colSpan="2">
                            <FormGroup controlId="formControlsTextarea">
                              <FormControl
                                rows="5"
                                componentClass="textarea"
                                bsClass="form-control"
                                placeholder="Here can be your description"
                                value={this.state.textArea}
                                data-gramm="false"
                                data-gramm_editor="false"
                                data-enable-grammarly="false"
                                onChange={this.handleTextChange.bind(this)}
                              />
                            </FormGroup>
                          </td>
                          <td colSpan='3' className="btn-holder table-actionbtn">
                            <Button className="btn-success" onClick={this.insertWeeklyWorkLog} disabled={this.state.disableSaveButton} data-toggle="tooltip" title="Save" fill>
                              <i className="fa fa-floppy-o" aria-hidden="true"></i>
                              <span>Save</span>
                            </Button>
                            <Button className="btn-clear" onClick={this.resetFields} data-toggle="tooltip" title="Clear" pullRight fill>
                              <i className="fa fa-eraser" aria-hidden="true"></i>
                              <span>Clear</span>
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Daily Hours Logged"
                ctTableResponsive
                content={
                  <div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="col-md-2">
                          <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                            <Select
                              value={Filter_CountrySelectedOption}
                              onChange={(e) => this.handleCountryChangeFilter(e)}
                              options={this.state.countries}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                              placeholder="Select Country"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-2">
                          <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                            <Select
                              value={Filter_DivisonSelectedOption}
                              onChange={(e) => this.handleDivisonChangeFilter(e)}
                              options={this.state.divisions}
                              isDisabled={this.state.disableDivisionFilter}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                              placeholder="Select Division"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-2">
                          <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                            <Select
                              value={Filter_selectedOption}
                              onChange={(e) => this.handleClientChangeFilter(e)}
                              options={this.state.clients}
                              isDisabled={this.state.disableClientFilter}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                              placeholder="Select Client"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-4">
                          <div className="InputFromTo">
                            <DayPickerInput
                              value={from}
                              placeholder="From"
                              format="LL"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                selectedDays: [from, { from, to }],
                                disabledDays: { after: to },
                                toMonth: to,
                                modifiers,
                                numberOfMonths: 2,
                                onDayClick: () => this.to.getInput().focus(),
                              }}
                              onDayChange={this.handleFromChange}
                            />{' '}
                            —{' '}
                            <span className="InputFromTo-to">
                              <DayPickerInput
                                ref={el => (this.to = el)}
                                value={to}
                                placeholder="To"
                                format="LL"
                                formatDate={formatDate}
                                parseDate={parseDate}
                                dayPickerProps={{
                                  selectedDays: [from, { from, to }],
                                  disabledDays: { before: from },
                                  modifiers,
                                  month: from,
                                  fromMonth: from,
                                  numberOfMonths: 2,
                                }}
                                onDayChange={this.handleToChange}
                              />
                            </span>
                            <Helmet>
                              <style>{`
                              .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                                background-color: #f0f8ff !important;
                                color: #4a90e2;
                              }
                              .InputFromTo .DayPicker-Day {
                                border-radius: 0 !important;
                              }
                              .InputFromTo .DayPickerInput input{
                                max-width: 140px !important;
                              }
                              .InputFromTo-to .DayPickerInput input{
                                max-width: 140px !important;
                              }
                              .InputFromTo .DayPicker-Day--start {
                                border-top-left-radius: 50% !important;
                                border-bottom-left-radius: 50% !important;
                              }
                              .InputFromTo .DayPicker-Day--end {
                                border-top-right-radius: 50% !important;
                                border-bottom-right-radius: 50% !important;
                              }
                              .InputFromTo .DayPickerInput-Overlay {
                                width: 350px;
                              }
                              .InputFromTo-to .DayPickerInput-Overlay {
                                margin-left: -198px;
                              }
                            `}</style>
                            </Helmet>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <Button className="btn-edit" fill data-toggle="tooltip" title="Search" onClick={() => this.prepareQueryObj()}>
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </Button>
                          <Button className="btn-clear ml-2" fill data-toggle="tooltip" title="Reset" onClick={() => this.clearFilters()}>
                            <i className="fa fa-eraser" aria-hidden="true"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <DataTable
                          title=""
                          className="daily-hours-table"
                          columns={columns}
                          noHeader
                          data={this.state.workLogs}
                          expandableRows
                          expandableRowsComponent={<CommentComponent />}
                          progressPending={loading}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={this.handlePerRowsChange}
                          onChangePage={this.handlePageChange}
                          conditionalRowStyles={conditionalRowStyles}
                          paginationRowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default DailyLog;
