import React, { Component } from "react";
import Select from 'react-select';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel
} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';

import Autosuggest from 'react-autosuggest';
import {RadioGroup, Radio} from 'react-radio-group';

import {
  fetchAllCountry,
  fetchAllDivision,
  fetchClients,
  fetchDivisionOnCountry,
  BASE_URL,
  checkClientAvailability,
  fetchClientsWithDetails
} from "../helper/helper.jsx"

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Dialog from 'react-bootstrap-dialog'

import Cookies from 'js-cookie';
import { CLIENT_TABLE_HEAD } from '../helper/data'
const axios = require('axios');

let clients_columns = CLIENT_TABLE_HEAD
let userData = {}
if (Cookies.get('userData')) {
  userData = JSON.parse(Cookies.get('userData'))
}

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      suggested_clients: [],
      selectedOption: null,
      filter_clients: [],
      all_clients: [],
      client: '',
      client_already_exist: false,
      CountrySelectedOption: null,
      countries: [],
      division: [],
      DivisonSelectedOption: null,
      disableDivision: true,
      disableClient: true,
      radioSelectedValue: '1',
      oldRecord: {},
      loading: false,
      totalRows: 0,
      perPage: 10,
    };
  }

  componentDidMount() {
    const { perPage } = this.state;
    this.setState({ loading: true });
    this.fetchAllCountryList()
    this.fetchAllClientList()
    this.fetchClientsWithDetails(1, perPage)
  }

  handleRadioChange = (value) => {
    if (value == 1) {
      this.setState({ client: ''})
    } else {
      this.setState({ selectedOption: null})
    }
    this.setState({
      radioSelectedValue: value
    });
    this.setState({radioSelectedValue: value});
  }

  handleCountryChange = (e) => {
    let temp = e.value
    this.setState({
      CountrySelectedOption: e,
    }, () => {
      fetchDivisionOnCountry(temp).then((data) => {
        this.setState({
          divisions: data.result,
          disableDivision: (temp != "") ? false : true
        })
      }).catch(err => console.log(err))
    });
  };

  handleDivisonChange = (e) => {
    let temp = e.value
    this.setState({
      DivisonSelectedOption: e,
      disableClient: (temp != "") ? false : true
    }, () => {
      if (this.state.selectedOption != null) {
        this.clientAvailable(this.state.selectedOption.value)
      }
      fetchClients().then((data) => {
        this.setState({
          clients: data[0].data.results.clients,
          // all_clients: data[0].data.results.clients_record
        })
      }).catch(err => console.log(err))
    });
    
  };

  handleClientChange = (e) => {
    let temp = e.value
    this.setState({
      selectedOption: e,
    }, () => {
      this.clientAvailable(temp)
    });
  };

  clientAvailable (temp) {
    checkClientAvailability({
      division_id : this.state.DivisonSelectedOption.value, 
      country_id : this.state.CountrySelectedOption.value, 
      client_id: temp}).then((data) => {
      let isExist = data.results[0].total_rows
      if (isExist) {
        this.setState({ client_already_exist : true })
      }
      else {
        this.setState({ client_already_exist : false })
      }
    }).catch(err => console.log(err))
  }


  fetchAllCountryList () {
    fetchAllCountry().then((data) => {
      this.setState({
        countries: data[0].data.result
      })
    }).catch(err => console.log(err))
  }

  renderButtonHTML = (data) => {
    return (
      <div className="table-btn-holder">
        <Button className="btn-edit" fill data-toggle="tooltip" title="Edit" onClick={() => this.editClient(data)}>
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </Button>
        <Button className="btn-delete ml-2" fill data-toggle="tooltip" title="Delete" onClick={() => this.deleteClient(data)}>
          <i className="fa fa-trash" aria-hidden="true"></i>
        </Button>
      </div>
    )
  }

  fetchAllClientList () {
    fetchClients().then((data) => {
      this.setState({
        filter_clients: data[0].data.results.clients,
      })
    }).catch(err => console.log(err))
  }
  fetchClientsWithDetails (page, perPage) {
    fetchClientsWithDetails(page, perPage).then((data) => {
      let all_client_list = data[0].data.results.clients_record
      all_client_list.map((value, index) => {
        if (value.total_record == 0) {
          all_client_list[index].action = this.renderButtonHTML(value)
        }
      })
      this.setState({
        totalRows: data[0].data.results.total,
        loading: false,
        filter_clients: data[0].data.results.clients,
        all_clients: all_client_list
      })
    }).catch(err => console.log(err))
  }


  submitRequest = () => {
    if (this.state.radioSelectedValue == 1) {
      if (this.state.CountrySelectedOption == null) {
        toast.error("Please select country.");
      }
      else if (this.state.DivisonSelectedOption == null) {
        toast.error("Please select division.");
      }
      else if (this.state.selectedOption == null) {
        toast.error("Please provide client.");
      }
      else {
        let url = BASE_URL + "/createNewClientLink"
      
        let req_data = {
          "division": this.state.DivisonSelectedOption.value,
          "country": this.state.CountrySelectedOption.value,
          "client": this.state.selectedOption.value,
          "oldRecord": this.state.oldRecord
        }
        axios.post(url, {
          data: req_data,
          token: userData.authToken
        }).then((response) => {
          this.resetFields();
          toast.success("Client Link Created!");
          this.fetchAllClientList()
          this.fetchClientsWithDetails(1, this.state.perPage)
        })
      }
      
    }
    else {
      if (this.state.CountrySelectedOption == null) {
        toast.error("Please select country.");
      }
      else if (this.state.DivisonSelectedOption == null) {
        toast.error("Please select division.");
      }
      else if (this.state.client == "") {
        toast.error("Please provide client.");
      }
      else {
        let url = BASE_URL + "/insertClient"
    
        let req_data = {
          "country": this.state.CountrySelectedOption.value,
          "division": this.state.DivisonSelectedOption.value,
          "client": this.state.client,
          "oldRecord": this.state.oldRecord
        }
        axios.post(url, {
          data: req_data,
          token: userData.authToken
        }).then((response) => {
          this.resetFields();
          toast.success("Client Created!");
          this.fetchAllClientList()
          this.fetchClientsWithDetails(1, this.state.perPage)
        })
      }
    }
  }

  editClient = (data) => {
    let clientFound = this.state.filter_clients.find((elem) => {
      return elem.value === data.client_id
    })
    let countryFound = this.state.countries.find((elem) => {
      return elem.value === data.country_id
    })

    fetchDivisionOnCountry(data.country_id).then((response) => {
      let foundDivision = response.result.find((elem) => {
        return elem.value === data.division_id
      })
      fetchClients().then((res) => {
        this.setState({
          oldRecord: data,
          CountrySelectedOption: countryFound,
          divisions: response.result,
          DivisonSelectedOption: foundDivision,
          disableDivision: (data.country_id != "") ? false : true,
          disableClient: (data.division_id != "") ? false : true,
          clients: res[0].data.results.clients,
          selectedOption: clientFound,
          client: clientFound.label,
          client_already_exist : true,
        })
        
      }).catch(err => console.log(err))
      
    }).catch(err => console.log(err))

  }

  deleteClient = (data) => {
    this.dialog.show({
      title: 'Confirmation',
      body: 'Are you sure you want to delete this country-divison-client link?',
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(() => {
          let url = BASE_URL + "/deleteClientLink"
          data.employee_id = userData["employee_id"]
          axios.post(url, {
            data: data,
            token: userData.authToken
          })
            .then((response) => {
              this.fetchAllClientList()
              this.resetFields();
              this.fetchClientsWithDetails(1, this.state.perPage)
            })
            .catch(function (error) {
              console.log(error);
            });
        })
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  handleClient = (e) => {
    this.setState({client: e.target.value})
  }

  resetFields = () => {
    this.setState({
      selectedOption: null,
      client: '',
      client_already_exist: false,
      CountrySelectedOption: null,
      DivisonSelectedOption: null,
      disableDivision: true,
      disableClient: true,
      radioSelectedValue: '1'
    });
  }

  getSuggestions = value => {
    let filter_clients = this.state.filter_clients
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
   
    let foundCountry = inputLength === 0 ? [] : filter_clients.filter(client =>
      client.label.toLowerCase().slice(0, inputLength) === inputValue
    );
    this.setState({
      client_already_exist: foundCountry.length > 0 ? true : false
    })

    return foundCountry
  };


  getSuggestionValue = suggestion => suggestion.label;

  renderSuggestion = suggestion => suggestion.label;

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggested_clients: this.getSuggestions(value)
    });
  };
 
  onSuggestionsClearRequested = () => {
    this.setState({
      suggested_clients: []
    });
  };
  suggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.setState({client: suggestionValue})
  }

  handlePageChange = async page => {
    const { perPage } = this.state;
    this.setState({ loading: true });
    this.fetchClientsWithDetails (page, perPage)
  }

  handlePerRowsChange = async (perPage, page) => {
    this.setState({ loading: true });
    this.fetchClientsWithDetails (page, perPage)
    this.setState({
      perPage,
    });
  }

  render() {
    const { value, suggested_clients, CountrySelectedOption, DivisonSelectedOption, selectedOption, loading, totalRows } = this.state;

    const inputProps = {
      placeholder: 'client name',
      value: this.state.client,
      onChange: this.handleClient,
      disabled:this.state.disableClient,
      className:"form-control"
    };

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col className= "col-md-offset-2 col-md-8">
              <Card
                title=""
                category=""
                content={
                  <div>
                    <ToastContainer />
                    <Dialog ref={(component) => { this.dialog = component }} />
                    <div className="row">
                      <div className="col-md-offset-3 col-md-6">
                      <label>Please Select Country</label>
                        <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                          <Select
                            value={CountrySelectedOption}
                            onChange={(e) => this.handleCountryChange(e)}
                            options={this.state.countries}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                          />
                        </FormGroup>
                        <label>Please Select Division</label>
                        <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                          <Select
                            value={DivisonSelectedOption}
                            onChange={(e) => this.handleDivisonChange(e)}
                            options={this.state.divisions}
                            isDisabled={this.state.disableDivision}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                          />
                        </FormGroup>
                        <FormGroup controlId="formControlsSelect" className="ClientSelectSeprate">
                          <RadioGroup
                            name="radio"
                            className = "radio-new-line"
                            selectedValue={this.state.radioSelectedValue}
                            onChange={this.handleRadioChange}>
                            <label>
                              <Radio value="1" /> Link existing client
                            </label>
                            <label>
                              <Radio value="2" /> Create new client and link it
                            </label>
                            
                          </RadioGroup>
                        </FormGroup>
                        <div style={(this.state.radioSelectedValue == 1) ? {display: 'block'} : {display: 'none'}}>
                          <label>Please Select Client Name</label>
                          <FormGroup controlId="formControlsTextarea">
                            <Select
                              value={selectedOption}
                              onChange={(e) => this.handleClientChange(e)}
                              options={this.state.clients}
                              isDisabled={this.state.disableClient}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body} 
                            />
                          </FormGroup>
                        </div>
                        <div style={(this.state.radioSelectedValue == 2) ? {display: 'block'} : {display: 'none'}}>
                          <label>Please Enter Client Name</label>
                          <FormGroup controlId="formControlsTextarea">
                          <Autosuggest
                            suggestions={suggested_clients}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            onSuggestionSelected={this.suggestionSelected}
                            inputProps={inputProps}
                          />
                          </FormGroup>
                        </div>

                        {(this.state.client_already_exist !== undefined && this.state.client_already_exist) ? <ControlLabel style={{ color: '#B00020' }}>* Client Already Exists</ControlLabel> : <ControlLabel></ControlLabel>}
                      </div>
                      
                      </div>
                      <div className="row">
                        <div className="col-md-offset-3 col-md-6">
                          <Button className="btn-success" onClick={this.submitRequest} disabled={this.state.client_already_exist} fill>
                            <i className="fa fa-floppy-o" data-toggle="tooltip" title="Save" aria-hidden="true"></i>
                            <span>Save</span>
                          </Button>
                          <Button className="btn-clear ml-2" onClick={this.resetFields} data-toggle="tooltip" title="Clear" pullRight fill>
                          <i className="fa fa-eraser" aria-hidden="true"></i>
                          <span>Clear</span>
                        </Button>
                        </div> 
                      </div>
                    </div>
                }
              />
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col  className= "col-md-offset-2 col-md-8">
              <Card
                title="Client List"
                ctTableResponsive
                content={
                  <div className="row">
                      <div className="col-md-12">
                      <DataTable
                        title=""
                        className="daily-hours-table"
                        columns={clients_columns}
                        data={this.state.all_clients}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationRowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                      />
                      </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Client;
