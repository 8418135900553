/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import history from '../../history';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import openSocket from 'socket.io-client';

import { ORIGIN } from '../../helper/data'
import {
  BASE_URL,
} from "../../helper/helper.jsx"


const axios = require('axios');

let userData = {}
if (Cookies.get('userData')) {
  userData = JSON.parse(Cookies.get('userData'))
}

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

class AdminNavbarLinks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      notificationLength: 0,
      menuItem: []
    }

  }

  componentDidMount() {
    if (Object.keys(userData).length > 0) {
      this.setState({
        userName: userData.employee_name
      })
    }

    this.fetchNotifications("1")
    
    if (ORIGIN.indexOf('localhost') > -1) {
      this.socket = openSocket.connect('http://localhost:3001', {
        secure: true,
        rejectUnauthorized: false,
        // path: '/socket.io'
      });
      
    }
    else if (ORIGIN.indexOf('worksheet') > -1) {
      this.socket = openSocket.connect('https://worksheet-log.herokuapp.com', {
        secure: true,
        rejectUnauthorized: false,
        // path: '/socket.io'
      });
    }
    else if (ORIGIN.indexOf('pmedtimesheet') > -1) {
      this.socket = openSocket.connect('https://pmedtimesheetb.azurewebsites.net', {
        secure: true,
        rejectUnauthorized: false,
        // path: '/socket.io'
      });
    }
    else if (ORIGIN.indexOf('timesheets') > -1) {
      this.socket = openSocket.connect('https://pmedtimesheetb.azurewebsites.net', {
        secure: true,
        rejectUnauthorized: false,
        // path: '/socket.io'
      });
    }
    var thisobj=this;
    this.socket.on('VERIFIED_LOGS_' + userData.employee_id , function (response) {
      thisobj.fetchNotifications("2")

    })
  }

  fetchNotifications(index) {
    let url = BASE_URL + "/fetchNotifications"

    axios.post(url, {
      employee_id: userData.employee_id,
      timeZone: timeZone,
      token: userData.authToken
    })
    .then((response) => {
      let result = response.data.results
      let notification_length = result.length
      if (index == 2)
        toast.info(notification_length + " Hour(s) Rejected.");
      this.setState({
        notificationLength: notification_length,
        menuItem: result 
      })
    })
  }
  

  handleClick = () => {
    Cookies.remove('userData');
    history.push('/timesheet/login');
    window.location.reload();
  }

  handleNotificationClick = () => {
    let url = BASE_URL + "/markNotificationAsRead"

    axios.post(url, {
      employee_id: userData.employee_id,
      token: userData.authToken
    })
    .then((response) => {
      this.setState({
        notificationLength: 0,
      })
      
    })
  }

  render() {
    const notification = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        {(this.state.notificationLength > 0) ? <span className="notification"> {this.state.notificationLength} </span> : ''}
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );
    return (
      <div>
        <ToastContainer />
        <Nav>
          {/* <NavItem eventKey={1} href="#">
            <i className="fa fa-dashboard" />
            <p className="hidden-lg hidden-md">Dashboard</p>
          </NavItem> */}
          <NavDropdown
            eventKey={2}
            title={notification}
            noCaret
            id="basic-nav-dropdown"
            onClick={this.handleNotificationClick}
          >
            
            {this.state.menuItem.map((prop, key) => { 
              if (this.state.menuItem.length > 0) {
                return (
                  <MenuItem key={key}>Rejected Log: {prop.work_date} | {prop.client_name} | {prop.work_time} </MenuItem>
                  ) 
              }
              
              })}
          </NavDropdown>
          {/* <NavItem eventKey={3} href="#">
            <i className="fa fa-search" />
            <p className="hidden-lg hidden-md">Search</p>
          </NavItem> */}
        </Nav>
        <Nav className="header-dropdown" pullRight>
          {/* <NavItem eventKey={1} href="#">
            Account
          </NavItem> */}
          <NavDropdown
            eventKey={2}
            title={this.state.userName}
            id="basic-nav-dropdown-right"
          >
            {/* <MenuItem eventKey={2.1}>Action</MenuItem> */}
            {/* <MenuItem divider /> */}
            <MenuItem eventKey={2.5} onClick={this.handleClick.bind(this)}>Log out</MenuItem>
          </NavDropdown>
          {/* <NavItem eventKey={3} href="#" onClick={this.handleClick.bind(this)}>
            Log out
          </NavItem> */}
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
